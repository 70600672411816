import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AxiosInstance from "utils/AxiosInstance";
import { LoadingSpinner } from "components/common";
import { Modal } from "components/ui";

function TeacherModalView({ data, closeModal }) {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [teacher, setTeacher] = useState(null);

    useEffect(() => {
        AxiosInstance({
            url: "/teachers/" + data.teacherId,
            method: "GET",
        }).then((response) => {
            setTeacher(response.data);

            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, []);

    return (
        <Modal handleClose={closeModal} title={t("Teacher information", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className="flex flex-col gap-4 w-full">
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                            <strong>{t("Full name", { ns: "common" })}:</strong>
                            {teacher.fullName}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Phone", { ns: "common" })}:</strong>
                            {teacher.phone}
                        </div>
                    </div>

                    {teacher.groups
                        ? teacher.groups.map((group, index) => (
                              <>
                                  <div className="border-t"></div>

                                  <div className="flex flex-col gap-2" key={index}>
                                      <div className="flex items-center gap-2">
                                          <strong>{t("Group", { ns: "common" })}:</strong>
                                          {group.groupName}
                                      </div>
                                      <div className="flex items-center gap-2">
                                          <strong>{t("Role", { ns: "common" })}:</strong>
                                          {t(group.typeName, { ns: "common" })}
                                      </div>
                                  </div>
                              </>
                          ))
                        : ""}

                    <div className="border-t"></div>

                    <div className="flex flex-row justify-center gap-2">
                        <Link to={"/institution/teachers/" + teacher.id + "/edit"} className="btn btn-primary">
                            {t("Edit", { ns: "button" })}
                        </Link>
                    </div>
                </div>
            )}
        </Modal>
    );
}

export default TeacherModalView;
