import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { NumericFormat } from "react-number-format";
import dayjs from "dayjs";
import { MenuItem, Select, Switch } from "@mui/material";
import { MobileTimePicker } from "@mui/x-date-pickers";

import AxiosInstance from "utils/AxiosInstance";
import { FormatMinutes } from "utils/Format";
import { LoadingSpinner } from "components/common";
import { Modal } from "components/ui";

function ActivityModalSchedule({ data, closeModal }) {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);

    const [weekDaysOptions, setWeekDaysOptions] = useState(null);
    const [activity, setActivity] = useState(null);
    const [schedule, setSchedule] = useState(null);

    const [scheduleGenerator, setScheduleGenerator] = useState({
        weekDays: [],
        timeStart: "2024-01-01T08:00",
        slots: null,
    });

    useEffect(() => {
        handleLoadData();
    }, []);

    const handleLoadData = () => {
        setLoading(true);

        Promise.all([
            AxiosInstance({
                url: "week-days",
                method: "GET",
            }).then((response) => {
                setWeekDaysOptions(response.data);
            }),

            AxiosInstance({
                url: "brands/" + data.brandId + "/activities/" + data.activityId,
                method: "GET",
            }).then((response) => {
                setActivity(response.data);
            }),

            AxiosInstance({
                url: "brands/" + data.brandId + "/activities/" + data.activityId + "/schedules",
                method: "GET",
            }).then((response) => {
                setSchedule(scheduleGrouping(response.data));
            }),
        ])
            .then(() => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    };

    const handleScheduleGeneratorChange = (event) => {
        const {
            target: { value, name },
        } = event;

        setScheduleGenerator({
            ...scheduleGenerator,
            [name]:
                // On autofill we get a stringified value.
                typeof value === "string" ? value.split(",") : value,
        });
    };

    const handleActiveChange = (timeIdx, dayId) => {
        setSchedule(
            schedule.map((item, itemIdx) => {
                if (timeIdx === itemIdx) {
                    return item.map((iii, idx) => {
                        if (iii.day.id === dayId) {
                            return {
                                ...iii,
                                active: !iii.active,
                            };
                        } else {
                            return iii;
                        }
                    });
                } else {
                    return item;
                }
            })
        );
    };

    const handleGenerateSchedule = () => {
        let days = scheduleGenerator.weekDays;
        let timeStart = dayjs.utc(scheduleGenerator.timeStart);
        let slots = scheduleGenerator.slots;

        let schedule = [];
        for (let i = 0; i < slots; i++) {
            days.map((day) => {
                schedule = [
                    ...schedule,
                    {
                        day: weekDaysOptions.find((item) => {
                            return item.id === day;
                        }),
                        time: timeStart,
                        active: true,
                    },
                ];
            });

            timeStart = timeStart.add(activity.activityLength, "minute");
        }

        setSchedule(scheduleGrouping(schedule));
    };

    function scheduleGrouping(schedule) {
        const groupSchedule = [];

        schedule.forEach((s) => {
            let key = Number(dayjs(s.time).format("Hmm"));
            if (!groupSchedule[key]) {
                groupSchedule[key] = [];
            }

            if (groupSchedule[key] && groupSchedule[key][s.day.id]) {
                groupSchedule[key][s.day.id] = [...groupSchedule[key][s.day.id], s];
            } else {
                groupSchedule[key][s.day.id] = s;
            }
        });

        return groupSchedule.filter((t) => {
            let tt = t.filter((a) => {
                return a;
            });

            return tt;
        });
    }

    function scheduleUngrouped(schedule) {
        const l = [];

        schedule.forEach((t) => {
            if (Array.isArray(t)) {
                t.forEach((b) => {
                    l.push(b);
                });
            } else {
                l.push(t);
            }
        });

        return l;
    }

    const handleSubmit = async () => {
        setSubmitting(true);

        AxiosInstance({
            url: "brands/" + data.brandId + "/activities/" + data.activityId + "/schedules",
            method: "POST",
            data: scheduleUngrouped(schedule),
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (response.status === 201) {
                    handleLoadData();
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Edit activity schedule", { ns: "common" })} big={true}>
            {isLoading ? (
                <LoadingSpinner />
            ) : schedule ? (
                <div className="flex flex-col gap-4 w-full">
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center gap-2">
                            <strong>{t("Activity", { ns: "common" })}:</strong>
                            {activity.name}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("City", { ns: "common" })}:</strong>
                            {activity.city.name}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Activity length", { ns: "common" })}:</strong>
                            <FormatMinutes minutes={activity.activityLength} />
                        </div>
                        <div className="flex gap-2">
                            <div className="border-t w-full"></div>
                        </div>

                        {Object.keys(schedule).length ? (
                            <>
                                <div className={"grid grid-cols-8 items-center gap-3"}>
                                    <div className="col-span-7 md:col-span-1 font-semibold"></div>
                                    {schedule[Object.keys(schedule)[0]].map((d) => (
                                        <div className="col-span-7 md:col-span-1 font-semibold" key={d.day.name}>
                                            {t(d.day.name, { ns: "lexicons" })}
                                        </div>
                                    ))}
                                </div>

                                {schedule.map((times, timeIdx) => (
                                    <div className={"grid grid-cols-8 items-center gap-3"} key={timeIdx}>
                                        <div className="col-start-1 col-span-1 font-semibold">
                                            {dayjs(times[Object.keys(times)[0]].time).format("HH:mm")}
                                        </div>
                                        {times.map((time, sIdx) => (
                                            <div className="col-span-7 md:col-span-1" key={sIdx}>
                                                <Switch
                                                    value={1}
                                                    checked={time.active}
                                                    onChange={() => handleActiveChange(timeIdx, time.day.id)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ))}

                                <div className="flex justify-center gap-2">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={isSubmitting}
                                        onClick={() => handleSubmit()}
                                    >
                                        {isSubmitting ? t("Saving", { ns: "button" }) : t("Save", { ns: "button" })}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-gray"
                                        disabled={isSubmitting}
                                        onClick={closeModal}
                                    >
                                        {t("Cancel", { ns: "button" })}
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <strong>Nie znaleziono harmonogramu dla tych zajęć. Utwórz nowy harmonogram.</strong>
                                <div className="flex items-center gap-2">
                                    <label>
                                        {t("What days of the week will activities be available?", { ns: "common" })}
                                    </label>
                                    <Select
                                        multiple
                                        name={"weekDays"}
                                        value={scheduleGenerator.weekDays}
                                        className="w-96"
                                        onChange={handleScheduleGeneratorChange}
                                    >
                                        {weekDaysOptions.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {t(option.name, { ns: "lexicons" })}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div className="flex items-center gap-2">
                                    <label>{t("What time do you start your first activity?", { ns: "common" })}</label>
                                    <MobileTimePicker
                                        name={"timeStart"}
                                        value={dayjs.utc(scheduleGenerator.timeStart)}
                                        className="form-datepicker w-32"
                                        onChange={(time) =>
                                            handleScheduleGeneratorChange({
                                                target: { name: "timeStart", value: dayjs.utc(time) },
                                            })
                                        }
                                    />
                                </div>
                                <div className="flex items-center gap-2">
                                    <label>{t("How many time slots to generate in one day?", { ns: "common" })}</label>
                                    <div className="form-field">
                                        <NumericFormat
                                            name={"slots"}
                                            value={scheduleGenerator.slots}
                                            className={"form-input"}
                                            onValueChange={(values) =>
                                                handleScheduleGeneratorChange({
                                                    target: { name: "slots", value: values.floatValue },
                                                })
                                            }
                                        ></NumericFormat>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-primary" onClick={handleGenerateSchedule}>
                                    {t("Generate", { ns: "button" })}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                t("400 | Bad Request", { ns: "common" })
            )}
        </Modal>
    );
}

export default ActivityModalSchedule;
