import React from "react";
import { FormField } from "./FormField";

export const PhoneField = ({
    id,
    label,
    className = "",
    fieldClassName,
    field: { name, value, ...fieldProps },
    form,
    required,
    validation,
    ...props
}) => {
    return (
        <FormField id={id} label={label} required={required} className={fieldClassName} formProps={form}>
            <input
                type="text"
                className={"form-input " + className}
                id={id}
                name={name}
                value={value}
                {...fieldProps}
                {...props}
            />
        </FormField>
    );
};
