import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import AxiosInstance from "utils/AxiosInstance";
import { AdvancedForm } from "components/forms/AdvancedForm";
import { BrandTrainerSchema } from "schemas/BrandTrainerSchema";
import { BrandAdminSchema } from "schemas/BrandAdminSchema";
import { InstitutionAdminSchema } from "schemas/InstitutionAdminSchema";

export const ProfileForm = ({ user, handleLoadData }) => {
    const { t } = useTranslation();

    let formSchema = [];

    if (user.roles.includes("ROLE_PROVIDER_ADMIN")) {
        formSchema = BrandAdminSchema();
    }

    if (user.roles.includes("ROLE_PROVIDER_TRAINER")) {
        formSchema = BrandTrainerSchema();
        formSchema.map((val) => {
            if (val.name === "contractType") {
                val.disabled = true;
            }

            return val;
        });
    }

    if (user.roles.includes("ROLE_INSTITUTION_ADMIN")) {
        formSchema = InstitutionAdminSchema();
    }

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "users",
            method: "PUT",
            data: values,
        })
            .then((response) => {
                handleLoadData();

                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <AdvancedForm
            schema={formSchema}
            onSubmit={handleSubmit}
            initialValues={user}
            buttonLabel={t("Save", { ns: "button" })}
            submittingButtonLabel={t("Saving", { ns: "button" })}
        />
    );
};
