import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, LoadingSpinner } from "components/common";
import { PayTelForm } from "components/payments/PayTelForm";
import { useSessionStorage } from "hooks/useSessionStorage";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui";

export const PaymentPage = () => {
    const params = useParams();
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [formData, setFormData] = useSessionStorage("payment_" + params.orderId, null);

    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [status, setStatus] = useState(null);

    useEffect(() => {
        AxiosInstance({
            url: "payments/status/" + params.orderId,
            method: "GET",
        })
            .then((response) => {
                setStatus(response.data);

                setTimeout(() => {
                    sessionStorage.removeItem("payment_" + params.orderId);
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                    setStatus(null);
                }, 300);
            });
    }, [params.orderId]);

    const handleSubmit = async (orderId) => {
        setSubmitting(true);

        AxiosInstance({
            url: "payments/cancel",
            method: "POST",
            data: {
                orderId: orderId,
            },
        })
            .then((response) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);

                setTimeout(() => {
                    if (response.data.status === 201) {
                        navigate("/");
                    }
                }, 1000);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return isLoading ? (
        <LoadingSpinner isFull={true} />
    ) : status === "PENDING" && formData ? (
        <div className={"flex flex-col items-center h-full w-full p-12 gap-8"}>
            <PayTelForm data={formData} />

            <Button
                type={"submit"}
                className={"btn btn-dark-outline"}
                onClick={() => handleSubmit(params.orderId)}
                disabled={isSubmitting}
            >
                {t("Cancel the payment and return to the dashboard", { ns: "button" })}
            </Button>
        </div>
    ) : (
        <div className={"flex flex-col items-center min-h-screen w-full p-12 gap-8"}>
            <AccessDenied />

            <Button
                type={"submit"}
                className={"btn btn-dark-outline"}
                onClick={() => handleSubmit(params.orderId)}
                disabled={isSubmitting}
            >
                {t("Cancel the payment and return to the dashboard", { ns: "button" })}
            </Button>
        </div>
    );
};
