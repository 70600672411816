import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import AxiosInstance from "utils/AxiosInstance";
import { LoadingSpinner } from "components/common";
import { Button, Modal } from "components/ui";

function ModalActivityDelete({ data, closeModal, handleLoadData }) {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [activity, setActivity] = useState(null);

    useEffect(() => {
        AxiosInstance({
            url: "brands/" + data.brandId + "/activities/" + data.activityId,
            method: "GET",
        })
            .then((response) => {
                setActivity(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, [data]);

    const handleConfirmDelete = async (event) => {
        setSubmitting(true);

        AxiosInstance({
            url: "brands/" + data.brandId + "/activities/" + data.activityId,
            method: "DELETE",
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (response.data.status === 201) {
                    closeModal();
                    handleLoadData();
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Remove activity", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : activity ? (
                <div className="flex flex-col gap-4 w-full">
                    <div className="text-center">
                        {t("Are you sure you want to remove the {{activity}} activity?", {
                            ns: "common",
                            activity: activity.name,
                        })}
                    </div>
                    <div className="flex flex-row justify-center gap-2">
                        <Button className={"btn-primary"} onClick={() => handleConfirmDelete()} disabled={isSubmitting}>
                            {t("Yes", { ns: "button" })}
                        </Button>
                        <Button className={"btn-gray"} onClick={() => closeModal()}>
                            {t("No", { ns: "button" })}
                        </Button>
                    </div>
                </div>
            ) : (
                t("No trainer found", { ns: "common" })
            )}
        </Modal>
    );
}

export default ModalActivityDelete;
