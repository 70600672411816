import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";
import { LanguageSwitcher } from "components/ui";
import { Logo } from "components/common/Logo";

export const ChooseAccountType = () => {
    const { t } = useTranslation();

    const theme = useTheme();
    const hidden = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <div className="flex flex-col min-h-screen p-8">
            {hidden ? (
                <div className="mx-auto">
                    <Logo />
                </div>
            ) : null}
            <div className="max-w-sm m-auto">
                <h3 className="mb-2">{t("Welcome to The Kidly!", { ns: "common" })}</h3>
                <p className="mb-8">
                    {t("To begin with, we need to determine in what form you will use the application.", {
                        ns: "common",
                    })}
                </p>
                <div className="flex flex-col w-full gap-4">
                    <Link to={"/signup/parent"} className="box" reloadDocument>
                        {t("I am a parent", { ns: "common" })}
                    </Link>
                    <Link to={"/signup/institution"} className="box" reloadDocument>
                        {t("I am the director of the institution", { ns: "common" })}
                    </Link>
                    <Link to={"/signup/brand"} className="box" reloadDocument>
                        {t("I am a service provider", { ns: "common" })}
                    </Link>
                </div>

                <div className="mt-8">
                    <LanguageSwitcher />
                </div>
            </div>
        </div>
    );
};
