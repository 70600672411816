import React from "react";
import { useTranslation } from "react-i18next";
import { FormField } from "./FormField";
import {
    Autocomplete,
    ListSubheader,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
    autocompleteClasses,
    Popper,
    styled,
    createFilterOptions,
} from "@mui/material";
import { VariableSizeList } from "react-window";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING,
        color: "var(--color-text)",
        fontSize: "0.875rem",
    };

    if (dataSet.hasOwnProperty("group")) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }

    const { key, ...optionProps } = dataSet[0];

    return (
        <Typography key={key} component="li" {...optionProps} noWrap style={inlineStyle}>
            {`${dataSet[0].key}`}
        </Typography>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (child.hasOwnProperty("group")) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0,
        },
    },
});

export const SelectField = ({
    label,
    fieldClassName,
    field: { name, value },
    form,
    required,
    options,
    isMulti = false,
    validation,
    handleBlur,
    ...props
}) => {
    const { t } = useTranslation();

    const onChange = (event, value) => {
        form.setFieldValue(name, value ? (isMulti ? value.map((v) => v) : value) : "");
    };

    const filterOptions = createFilterOptions({
        matchFrom: "start",
        stringify: (option) => option.name,
    });

    return (
        <FormField id={name} label={label} required={required} className={fieldClassName} formProps={form}>
            <Autocomplete
                onBlur={handleBlur}
                multiple={isMulti}
                size="small"
                key={"autocomplete_" + name}
                options={options}
                filterOptions={filterOptions}
                PopperComponent={StyledPopper}
                ListboxComponent={ListboxComponent}
                renderInput={(params) => <TextField {...params} placeholder={t("Select...", { ns: "common" })} />}
                renderOption={(props, option, state) => [props, option, state.index]}
                value={value || (isMulti ? [] : null)}
                isOptionEqualToValue={(option, value) =>
                    value ? (option.value ? option.value === value.value : option.id === value.id) : ""
                }
                name={name}
                id={name}
                onChange={onChange}
                getOptionLabel={(option) => option.name}
                {...props}
            />
        </FormField>
    );
};
