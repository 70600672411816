import React from "react";
import { GridToolbar, DataGrid as MuiDataGrid } from "@mui/x-data-grid";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";

export const DataGrid = ({ rows, columns, enableSearch = false, staticHeight, openModal, ...props }) => {
    return (
        <div className="table-box" style={{ flex: "1 1 auto", height: staticHeight ? "420px" : "100%" }}>
            <MuiDataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 25 },
                    },
                }}
                // filterModel={filterModel}
                pageSizeOptions={[10, 25, 50]}
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnResize
                width={"100%"}
                slots={{
                    toolbar: enableSearch && GridToolbar,
                    columnSortedDescendingIcon: IoChevronUpOutline,
                    columnSortedAscendingIcon: IoChevronDownOutline,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                sx={{
                    "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
                        paddingLeft: ".5rem",
                        paddingRight: ".5rem",
                    },
                    "& .MuiDataGrid-cell": {
                        borderColor: "#9ca3af",
                    },
                    "& .MuiDataGrid-row--lastVisible .MuiDataGrid-cell": {
                        borderBottom: "1px solid #9ca3af",
                    },
                    "& .MuiDataGrid-cell:focus-within": {
                        outline: "none",
                    },
                    "& .MuiDataGrid-columnHeader:focus-within": {
                        outline: "none",
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderColor: "#9ca3af",
                    },
                    "& .MuiDataGrid-footerContainer p": {
                        margin: 0,
                    },
                }}
                {...props}
            />
        </div>
    );
};
