import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui";

export const ButtonLoginByEmail = ({ handleLoginBy }) => {
    const { t } = useTranslation();

    return (
        <Button className="text-primary mx-auto !py-1" onClick={() => handleLoginBy("email")}>
            {t("Login by email", { ns: "common" })}
        </Button>
    );
};
