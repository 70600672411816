import React, { useEffect, useState } from "react";

import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";
import KidForm from "./components/KidForm";

export const KidAddPage = () => {
    const [permissionsLoading, setPermissionsLoading] = useState(true);
    const [permissions, setPermissions] = useState(false);
    const [permissionsMessage, setPermissionsMessage] = useState("");

    useEffect(() => {
        AxiosInstance({
            url: "kids",
            method: "GET",
        })
            .then((response) => {
                setPermissions(true);

                setTimeout(() => {
                    setPermissionsLoading(false);
                }, 300);
            })
            .catch((error) => {
                setPermissions(false);
                setPermissionsMessage(error.response.data.message);

                setTimeout(() => {
                    setPermissionsLoading(false);
                }, 300);
            });
    }, [permissions]);

    return (
        <Dashboard contentClass={"kids"}>
            {permissionsLoading ? (
                <LoadingSpinner />
            ) : permissions ? (
                <KidForm />
            ) : (
                <AccessDenied message={permissionsMessage} />
            )}
        </Dashboard>
    );
};
