import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IoCloseOutline, IoWalletOutline } from "react-icons/io5";

import { FormatDate, FormatPrice } from "utils/Format";
import { DataGrid } from "components/grid";
import { ContentHeading, OptionMenu } from "components/ui";
import ModalBillingPayments from "./ModalBillingPayments";
import ModalBillingDeleteKid from "./ModalBillingDeleteKid";

function BillingList({ billings }) {
    const { t } = useTranslation();

    const [modal, setModal] = useState({ show: false, data: null });

    const columns = [
        {
            field: "brand",
            headerName: t("Brand", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${(value && value.name) || "---"}`,
        },
        {
            field: "institution",
            headerName: t("Institution", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${(value && value.name) || "---"}`,
        },
        {
            field: "whoPay",
            headerName: t("Who pay", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => {
                if (row.type.value === "PARENT_PAY_BRAND") {
                    return row.whoPays.fullName;
                } else if (row.type.value === "INSTITUTION_PAY_BRAND") {
                    return row.institution.name;
                }

                return "---";
            },
        },
        {
            field: "kid",
            headerName: t("Kid", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${(value && value.fullName) || "---"}`,
        },
        {
            field: "activities",
            headerName: t("Activities", { ns: "common" }),
            flex: 1,
            minWidth: 320,
            valueGetter: (value, row) => {
                return row.activity
                    .map((a) => {
                        return a.name;
                    })
                    .join(", ");
            },
        },
        {
            field: "amount",
            headerName: t("Amount fee", { ns: "common" }),
            width: 120,
            sortable: false,
            renderCell: (params) => {
                return <FormatPrice price={params.row.nextPayment.amount} />;
            },
        },
        {
            field: "date",
            headerName: t("Next pay", { ns: "common" }),
            width: 150,
            sortable: false,
            renderCell: (params) => {
                return <FormatDate date={params.row.nextPay} />;
            },
        },
        {
            field: "actions",
            headerName: "",
            align: "right",
            sortable: false,
            width: 120,
            renderCell: (params) => {
                let options = [
                    {
                        text: t("Payments", { ns: "button" }),
                        icon: <IoWalletOutline size={"1.25em"} />,
                        menuItemProps: {
                            onClick: () => openModal(params.row, "payments"),
                        },
                    },
                ];

                params.row.active &&
                    params.row.kid &&
                    options.push({
                        text: t("Remove kid from activity", { ns: "button" }),
                        icon: <IoCloseOutline size={"1.25em"} />,
                        menuItemProps: {
                            onClick: () => openModal(params.row, "removeKid"),
                        },
                    });

                return <OptionMenu options={options} />;
            },
        },
    ];

    useEffect(() => {}, []);

    const openModal = (data, type = "view") => {
        setModal({ show: true, data: data, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
    };

    return (
        <>
            <ContentHeading tag="h3">
                <IoWalletOutline size={"1.5rem"} />
                {t("Billings", { ns: "common" })}
            </ContentHeading>

            <DataGrid rows={billings} columns={columns} enableSearch={true} />

            {modal.show && modal.data && modal.type === "payments" && (
                <ModalBillingPayments billing={modal.data} closeModal={handleModalClose} />
            )}

            {modal.show && modal.data && modal.type === "removeKid" && (
                <ModalBillingDeleteKid billing={modal.data} closeModal={handleModalClose} />
            )}
        </>
    );
}

export default BillingList;
