import React from "react";
import { useTranslation } from "react-i18next";
import AuthCode from "react-auth-code-input";

import { Button, ImgWithFallback } from "components/ui";
import { Layout } from "./Layout";

import signUpWebP from "../img/signup-step-1.webp";
import signUpPNG from "../img/signup-step-1.png";

export const Step2 = ({ handleChange, nextStep, resetSignUp, reSendCodeOTP, loginBy, isSubmitting, data, error }) => {
    const { t } = useTranslation();

    const signUpImg = (
        <ImgWithFallback src={signUpWebP} fallback={signUpPNG} className="flex max-w-xl m-auto" alt="Rejestracja" />
    );

    return (
        <Layout signUpImg={signUpImg}>
            <h3>{t("Verification", { ns: "common" })}</h3>
            <p className="mb-0">
                {t("In a moment, you will receive a verification code to the phone number you provided.", {
                    ns: "common",
                })}
            </p>

            <div className="flex flex-col w-full gap-2">
                <div className="form-field">
                    <label htmlFor="code" className="form-label">
                        {t("Enter the 6-digit verification code", { ns: "common" })}
                    </label>
                    <AuthCode
                        containerClassName="flex gap-2"
                        allowedCharacters="numeric"
                        inputClassName="form-input text-center w-1/6"
                        onChange={handleChange("code")}
                    />
                </div>

                <span className={"text-gray text-sm text-end cursor-pointer"} onClick={reSendCodeOTP}>
                    {t("Resend verification code", { ns: "button" })}
                </span>
            </div>

            <Button className={"btn-black w-full"} onClick={nextStep} disabled={isSubmitting}>
                {isSubmitting ? t("Sending", { ns: "button" }) : t("Next", { ns: "button" })}
            </Button>
        </Layout>
    );
};
