import React, { useEffect, useState } from "react";

import { LoadingSpinner } from "components/common";

const formStyle = {
    layout: "default",
    theme: "default",
    color: {
        primary: "#94FA46",
        secondary: "",
        border: "",
        surface: "",
        header: {
            text: "",
            background: "",
        },
        body: {
            text: "#313333",
            background: "",
        },
    },
    font: "",
};

export const PayTelForm = ({ data }) => {
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const scriptTag = document.createElement("script");
        scriptTag.src = process.env.REACT_APP_PAYTEL_SCRIPT.replace("{transactionID}", data.transactionID);

        setTimeout(() => {
            document.body.appendChild(scriptTag);
            setLoading(false);
        }, 1000);
    }, [data.transactionID]);

    return (
        <div className={"box flex min-h-80 w-full max-w-4xl !p-0 overflow-hidden"} style={{ maxHeight: 700 }}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <form
                    className={"w-full overflow-y-auto box-content paymentSPG"}
                    // style={{ paddingRight: "17px" }}
                    spg-context={data.formContext}
                    spg-config={JSON.stringify(data.formConfig)}
                    spg-style={JSON.stringify(formStyle)}
                ></form>
            )}
        </div>
    );
};
