import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, LoadingSpinner } from "components/common";
import { Modal } from "components/ui";
import GroupForm from "./GroupForm";

function GroupEditModal({ data, closeModal, handleLoadGroups }) {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const [isLoading, setLoading] = useState(true);
    const [group, setGroup] = useState(null);

    useEffect(() => {
        AxiosInstance({
            url: "institutions/" + currentUser.institution.id + "/groups/" + data.groupId,
            method: "GET",
        })
            .then((response) => {
                setGroup(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, []);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "institutions/" + currentUser.institution.id + "/groups/" + data.groupId,
            method: "PUT",
            data: values,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (response.status === 201) {
                    handleLoadGroups();
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Edit group", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : group ? (
                <GroupForm handleSubmit={handleSubmit} group={group} />
            ) : (
                <AccessDenied />
            )}
        </Modal>
    );
}

export default GroupEditModal;
