import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";
import KidForm from "./components/KidForm";

export const KidEditPage = () => {
    const params = useParams();
    const [permissionsLoading, setPermissionsLoading] = useState(true);
    const [permissions, setPermissions] = useState(false);
    const [permissionsMessage, setPermissionsMessage] = useState("");
    const [kid, setKid] = useState(null);

    useEffect(() => {
        if (params.kidId) {
            AxiosInstance({
                url: "kids/" + params.kidId,
                method: "GET",
            })
                .then((response) => {
                    setPermissions(true);
                    setKid(response.data);

                    setTimeout(() => {
                        setPermissionsLoading(false);
                    }, 300);
                })
                .catch((error) => {
                    setPermissions(false);
                    setPermissionsMessage(error.response.data.message);

                    setTimeout(() => {
                        setPermissionsLoading(false);
                    }, 300);
                });
        }
    }, [permissions]);

    return (
        <Dashboard contentClass={"kids"}>
            {permissionsLoading ? (
                <LoadingSpinner />
            ) : permissions ? (
                <KidForm kid={kid} />
            ) : (
                <AccessDenied message={permissionsMessage} />
            )}
        </Dashboard>
    );
};
