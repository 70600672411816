import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSessionStorage } from "hooks/useSessionStorage";
import AxiosInstance from "utils/AxiosInstance";
import { AdvancedForm } from "components/forms/AdvancedForm";
import { Alerts, Box, Button, ContentHeading, Modal } from "components/ui";

function InstitutionJoinModal({ kidId, closeModal }) {
    const { t } = useTranslation();
    const [user, setUser] = useSessionStorage("user");

    const [isSubmitting, setSubmitting] = useState(false);
    const [alerts, setAlerts] = useState(null);
    const [data, setData] = useState(null);

    const handleChange = (input) => (e) => {
        setData((data) => {
            return {
                ...data,
                [input]: e.target.value,
            };
        });
    };

    const handleSubmit = async () => {
        setAlerts(null);
        setSubmitting(true);

        AxiosInstance({
            url: "kids/" + kidId + "/institutions/join",
            method: "POST",
            data: data,
        })
            .then((response) => {
                setAlerts(response.data);

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                if (error.response && Object.keys(error.response.data).length > 0) {
                    setAlerts(error.response.data);
                } else if (error.request) {
                    setAlerts({ status: error.request.status, message: error.message });
                } else {
                    setAlerts(error.message);
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Find institution", { ns: "common" })}>
            {alerts && <Alerts alerts={alerts} />}

            <div className="grid grid-cols-6 gap-4">
                <div className="form-field col-span-full">
                    <label htmlFor="code" className="form-label">
                        {t("Institution code", { ns: "common" })}
                    </label>
                    <input
                        type="text"
                        name="code"
                        id="code"
                        className="form-input"
                        onChange={handleChange("code")}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") handleSubmit();
                        }}
                    />
                </div>

                <Button
                    className={"btn-primary col-span-full md:col-span-2"}
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? t("Sending", { ns: "button" }) : t("Save", { ns: "button" })}
                </Button>
            </div>
        </Modal>
    );
}

export default InstitutionJoinModal;
