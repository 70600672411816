import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { AddressSchema } from "./AddressSchema";

export const BrandAdminSchema = () => {
    const { t } = useTranslation();

    return [
        {
            name: "firstName",
            label: t("First name", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "lastName",
            label: t("Last name", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "email",
            label: t("E-mail", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 180,
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "phone",
            label: t("Phone", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 20,
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "address",
            componentType: "array",
            schema: AddressSchema(),
        },
    ];
};
