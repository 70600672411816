import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormField } from "./FormField";

export const PasswordField = ({
    id,
    label,
    className = "",
    fieldClassName,
    field: { name, value, ...fieldProps },
    form,
    required,
    validation,
    ...props
}) => {
    const { t } = useTranslation();

    const feedback = {
        0: t("too short", { ns: "common" }),
        1: t("weak", { ns: "common" }),
        2: t("okay", { ns: "common" }),
        3: t("good", { ns: "common" }),
        4: t("strong", { ns: "common" }),
    };

    const [validate, setValidate] = useState({
        hasLow: false,
        hasCap: false,
        hasNumber: false,
        hasSpecial: false,
        has8digit: false,
    });

    const [passwordValidate, setPasswordValidate] = useState(null);

    useEffect(() => {
        const strength = Object.values(validate).reduce((a, item) => a + item, 0);
        setPasswordValidate({ strength: strength, feedback: feedback[strength] });
    }, [validate]);

    const validatePassword = (e) => {
        let password = e.target.value;

        form.setFieldValue(name, password, true);

        if (password.length > 7) {
            // setValidate((o) => ({ ...o, has8digit: true }));

            if (password.match(/\d+/g)) {
                setValidate((o) => ({ ...o, hasNumber: true }));
            } else {
                setValidate((o) => ({ ...o, hasNumber: false }));
            }

            if (password.match(/[A-Z]+/g)) {
                setValidate((o) => ({ ...o, hasCap: true }));
            } else {
                setValidate((o) => ({ ...o, hasCap: false }));
            }

            if (password.match(/[a-z]+/g)) {
                setValidate((o) => ({ ...o, hasLow: true }));
            } else {
                setValidate((o) => ({ ...o, hasLow: false }));
            }

            if (password.match(/[^A-Za-z0-9]+/g)) {
                setValidate((o) => ({ ...o, hasSpecial: true }));
            } else {
                setValidate((o) => ({ ...o, hasSpecial: false }));
            }
        } else {
            setValidate({
                hasLow: false,
                hasCap: false,
                hasNumber: false,
                hasSpecial: false,
                has8digit: false,
            });
        }
    };

    return (
        <FormField id={id} label={label} required={required} className={fieldClassName} formProps={form}>
            <input
                type="password"
                className={"form-input " + className}
                minLength={8}
                maxLength={64}
                id={id}
                name={name}
                value={value}
                required={required}
                onChange={validatePassword}
                // {...fieldProps}
                {...props}
            />
            {name === "password" && passwordValidate && value ? (
                <>
                    <progress
                        hidden={value.length === 0}
                        className={`password-strength strength-${passwordValidate.strength}`}
                        value={passwordValidate.strength}
                        max="4"
                    />
                    <div
                        className={`password-feedback strength-${passwordValidate.strength}`}
                        hidden={value.length === 0}
                    >
                        {passwordValidate.feedback}
                    </div>
                </>
            ) : null}
        </FormField>
    );
};
