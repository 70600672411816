import React from "react";

export const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className="footer">
            <div>&copy; {currentYear}, The Kidly</div>
        </div>
    );
};
