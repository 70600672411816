import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export const getInitialValues = (defaultValues, initialValues) => {
    if (!initialValues) return defaultValues;

    return { ...defaultValues, ...initialValues };
};

export const getDefaultValues = (schema) => {
    return schema
        .filter((val) => !val.condition)
        .reduce((acc, val) => {
            let defaultValue;

            if (val.componentType === "array") {
                defaultValue = val.schema.reduce((a, v) => {
                    switch (v.componentType) {
                        case "text":
                        case "textarea":
                        case "select":
                        case "radioGroup":
                            defaultValue = "";
                            break;
                        case "checkbox":
                        case "checkboxSwitch":
                            defaultValue = false;
                            break;
                        case "datePicker":
                        case "time":
                            defaultValue = null;
                            break;
                        default:
                            defaultValue = "";
                    }

                    return { ...a, [v.name]: v.defaultValue || defaultValue };
                }, {});

                defaultValue = [defaultValue];
            } else if (val.componentType === "select" && val.isMulti) {
                defaultValue = [];
            } else {
                switch (val.componentType) {
                    case "text":
                    case "textarea":
                    case "select":
                    case "radioGroup":
                        defaultValue = "";
                        break;
                    case "checkbox":
                    case "checkboxSwitch":
                        defaultValue = false;
                        break;
                    case "datePicker":
                    case "time":
                        defaultValue = null;
                        break;
                    default:
                        defaultValue = "";
                }
            }

            return {
                ...acc,
                [val.name]: val.defaultValue || defaultValue,
            };
        }, {});
};

export const useValidationSchema = (schema) => {
    const { t } = useTranslation("validation");

    const validationObject = schema.reduce((acc, val) => {
        let validationType;

        if (val.componentType === "select" && val.isMulti) {
            validationType = Yup.array();

            if (val.required && validationType) {
                validationType = validationType.min(1, t("The {{label}} field is required.", { label: val.label }));
            }
        } else {
            switch (val.componentType) {
                case "text":
                case "textarea":
                case "radioGroup":
                    validationType = Yup.string().nullable();
                    break;
                case "url":
                    validationType = Yup.string().matches(
                        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                        t("Enter correct url.")
                    );
                    break;
                case "number":
                    validationType = Yup.number().integer();
                    break;
                case "email":
                    validationType = Yup.string().email(t("Invalid email address."));
                    break;
                case "phone":
                    validationType = Yup.string().matches(/^[0-9]{9}$/, t("The phone number not valid."));
                    break;
                case "select":
                    validationType = Yup.object();
                    break;
                case "datePicker":
                    validationType = Yup.date();
                    break;
                case "checkbox":
                case "switch":
                default:
                    validationType = null;
            }

            if (val.required && validationType) {
                if (val.condition) {
                    validationType = validationType.when(val.condition.key, (key, schema) => {
                        if (val.condition.value.includes(key.value)) {
                            return validationType.required(t("The {{label}} field is required.", { label: val.label }));
                        }
                        // is: (key) => val.condition.value.includes(key.value),
                        // then: validationType.required(t("The {{label}} field is required.", { label: val.label })),
                        // otherwise: validationType.string(),
                    });
                } else {
                    validationType = validationType.required(
                        t("The {{label}} field is required.", { label: val.label })
                    );
                }
            }

            if (val.validation && validationType) {
                let validation = val.validation;

                if (validation.regex) {
                    validationType = validationType.matches(validation.regex.expression, {
                        message: validation.regex.message,
                    });
                }
            }
        }

        return { ...acc, ...(validationType && { [val.name]: validationType }) };
    }, {});

    return Yup.object().shape(validationObject);
};
