import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import AxiosInstance from "utils/AxiosInstance";
import { LoadingSpinner } from "components/common";
import { Button, Modal } from "components/ui";

function TrainerModalDelete({ data, closeModal, handleLoadData }) {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [trainer, setTrainer] = useState(null);

    useEffect(() => {
        AxiosInstance({
            url: "brands/" + data.brandId + "/trainers/" + data.trainerId,
            method: "GET",
        })
            .then((response) => {
                setTrainer(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, [data]);

    const handleConfirmDeleteTrainer = async (event) => {
        setSubmitting(true);

        AxiosInstance({
            url: "brands/" + data.brandId + "/trainers/" + data.trainerId,
            method: "DELETE",
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (response.data.status === 201) {
                    closeModal();
                    handleLoadData();
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Remove trainer", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : trainer ? (
                <div className="flex flex-col gap-4 w-full">
                    <div className="text-center">
                        {t("Are you sure you want to remove the {{trainer}} trainer from the brand?", {
                            ns: "common",
                            trainer: trainer.fullName,
                        })}
                    </div>
                    <div className="flex flex-row justify-center gap-2">
                        <Button
                            className={"btn-primary"}
                            onClick={() => handleConfirmDeleteTrainer()}
                            disabled={isSubmitting}
                        >
                            {t("Yes", { ns: "button" })}
                        </Button>
                        <Button className={"btn-gray"} onClick={() => closeModal()}>
                            {t("No", { ns: "button" })}
                        </Button>
                    </div>
                </div>
            ) : (
                t("No trainer found", { ns: "common" })
            )}
        </Modal>
    );
}

export default TrainerModalDelete;
