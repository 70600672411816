import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import { Popover } from "@mui/material";
import "flag-icons";

function FlagIcon({ countryCode = "" }) {
    if (countryCode === "en") {
        countryCode = "gb";
    }

    return <span className={`fi fis fiCircle fi-${countryCode}`} />;
}

// https://github.com/simplelocalize/nextjs-tailwind-language-selector/tree/main

export const LanguageSwitcher = ({ onlyFlag = false }) => {
    const { i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    const isOpen = Boolean(anchorEl);
    const id = isOpen ? "languageSelector" : undefined;

    const languages = {
        en: { name: "English" },
        pl: { name: "Polski" },
    };

    const selectedLanguage = Object.keys(languages).find((language) => {
        if (language === i18n.resolvedLanguage) return language;
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = async (language) => {
        await i18n.changeLanguage(language);
        handleClose();
    };

    return (
        <>
            <div className="flex items-center justify-center z-40">
                <div className={"relative inline-block text-left" + (onlyFlag === false && " w-36")}>
                    <button
                        aria-describedby={id}
                        variant="contained"
                        onClick={handleClick}
                        type="button"
                        className={
                            onlyFlag === false
                                ? "inline-flex items-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 gap-2 bg-white text-sm hover:bg-gray-50"
                                : "flex"
                        }
                        id={id}
                        aria-haspopup="true"
                        aria-expanded={isOpen}
                    >
                        <FlagIcon countryCode={selectedLanguage} />
                        {onlyFlag === false && (
                            <>
                                {languages[selectedLanguage].name}
                                <div className="grow"></div>
                                {isOpen ? (
                                    <IoChevronUpOutline size={"1.25em"} />
                                ) : (
                                    <IoChevronDownOutline size={"1.25em"} />
                                )}
                            </>
                        )}
                    </button>

                    <Popover
                        id={id}
                        open={isOpen}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        className={onlyFlag === true ? "mt-6" : "mt-2"}
                    >
                        <div
                            className={
                                "origin-top-right w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                            }
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="language-selector"
                        >
                            <div className="flex flex-col py-1 gap-1" role="none">
                                {Object.keys(languages).map((language, index) => (
                                    <button
                                        key={language}
                                        className={`${
                                            i18n.resolvedLanguage === language ? "bg-gray-100" : "bg-white"
                                        } flex items-center px-4 py-2 gap-2 text-sm text-left hover:bg-gray-100`}
                                        onClick={() => handleLanguageChange(language)}
                                        role="menuitem"
                                    >
                                        <FlagIcon countryCode={language} />
                                        {languages[language].name}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </Popover>
                </div>
            </div>
        </>
    );
};
