import React from "react";
import { useTranslation } from "react-i18next";

import { Button, ImgWithFallback } from "components/ui";
import { Layout } from "./Layout";

import loginWebP from "../img/login-step-1.webp";
import loginPNG from "../img/login-step-1.png";
import { ButtonSignUp } from "./ButtonSignUp";
import { ButtonLoginByPhone } from "./ButtonLoginByPhone";

export const ByEmailStep1 = ({ handleChange, handleLoginBy, nextStep, isSubmitting }) => {
    const { t } = useTranslation();

    const loginImg = (
        <ImgWithFallback src={loginWebP} fallback={loginPNG} className="flex max-w-xl m-auto" alt="Login" />
    );

    return (
        <Layout loginImg={loginImg}>
            <h3>{t("Welcome to The Kidly!", { ns: "common" })}</h3>
            <p className="mb-0">
                {t("Managing children's services and activities has never been so easy!", { ns: "common" })}
            </p>

            <div className="flex flex-col w-full gap-2">
                <div className="form-field">
                    <label htmlFor="email" className="form-label">
                        {t("Email", { ns: "common" })}
                    </label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-input"
                        onChange={handleChange("email")}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") nextStep();
                        }}
                    />
                </div>
            </div>

            <Button className={"btn-primary w-full"} onClick={nextStep} disabled={isSubmitting}>
                {isSubmitting ? t("Sending", { ns: "button" }) : t("Next", { ns: "button" })}
            </Button>

            <ButtonLoginByPhone handleLoginBy={handleLoginBy} />

            <ButtonSignUp />
        </Layout>
    );
};
