import React from "react";

export const useSessionStorage = (storageKey, fallbackState, isString = false) => {
    const [value, setValue] = React.useState(
        (isString ? sessionStorage.getItem(storageKey) : JSON.parse(sessionStorage.getItem(storageKey))) ??
            fallbackState
    );

    React.useEffect(() => {
        sessionStorage.setItem(storageKey, isString ? value : JSON.stringify(value));
    }, [value, storageKey, isString]);

    return [value, setValue];
};
