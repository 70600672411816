import React from "react";
import dayjs from "dayjs";

import { MobileTimePicker } from "@mui/x-date-pickers";
import { IoCalendarOutline } from "react-icons/io5";
import { FormField } from "./FormField";

export const TimePicker = ({
    label,
    className = "",
    fieldClassName,
    field: { name, value, ...fieldProps },
    form,
    required,
    validation,
    ...props
}) => {
    return (
        <FormField id={name} label={label} required={required} className={fieldClassName} formProps={form}>
            <MobileTimePicker
                value={value ? dayjs(value) : value}
                onChange={(value) => {
                    form.setFieldValue(name, dayjs(value).format(), true);
                }}
                slots={{ openPickerIcon: IoCalendarOutline }}
                slotProps={{
                    textField: { size: "small" },
                    sx: { "&.MuiInputBase-root": { border: "4px solid red" } },
                }}
                className="form-datepicker"
                {...props}
            />
        </FormField>
    );
};
