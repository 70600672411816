import React from "react";
import { useTranslation } from "react-i18next";

import { Button, ImgWithFallback } from "components/ui";
import { Layout } from "./Layout";

import signUpWebP from "../img/signup-step-1.webp";
import signUpPNG from "../img/signup-step-1.png";
import { Checkbox, FormControlLabel } from "@mui/material";

export const Step5 = ({ handleChange, nextStep, resetSignUp, reSendCodeOTP, loginBy, isSubmitting, data, error }) => {
    const { t } = useTranslation();

    const signUpImg = (
        <ImgWithFallback src={signUpWebP} fallback={signUpPNG} className="flex max-w-xl m-auto" alt="Rejestracja" />
    );

    return (
        <Layout signUpImg={signUpImg}>
            <h3>{t("Welcome to The Kidly!", { ns: "common" })}</h3>
            <p className="mb-0">
                {t("We still need your consents to complete the registration process.", { ns: "common" })}
            </p>

            <div className="flex flex-col w-full gap-2">
                <FormControlLabel
                    required
                    control={<Checkbox onChange={handleChange("privacyPolicy")} checked={data.privacyPolicy} />}
                    label={
                        <a href="https://thekidly.com/polityka-prywatnosci/" target="_blank" rel="noopener noreferrer">
                            {t("Privacy Policy", { ns: "common" })}
                        </a>
                    }
                />
                <FormControlLabel
                    required
                    control={<Checkbox onChange={handleChange("termsConditions")} checked={data.termsConditions} />}
                    label={
                        <a href="https://thekidly.com/regulamin/" target="_blank" rel="noopener noreferrer">
                            {t("Terms and conditions", { ns: "common" })}
                        </a>
                    }
                />
                {/* <FormControlLabel
                    required
                    control={
                        <Checkbox onChange={handleChange("marketingAgreement")} checked={data.marketingAgreement} />
                    }
                    label={
                        <a href="/marketing-agreement" target="_blank">
                            {t("Marketing consents", { ns: "common" })}
                        </a>
                    }
                /> */}
            </div>

            <Button className={"btn-primary w-full"} onClick={nextStep} disabled={isSubmitting}>
                {isSubmitting ? t("Sending", { ns: "button" }) : t("Next", { ns: "button" })}
            </Button>

            {error === true && (
                <Button className={"btn-gray w-full"} onClick={resetSignUp}>
                    {t("Back", { ns: "button" })}
                </Button>
            )}
        </Layout>
    );
};
