import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";
import KidForm from "./components/KidForm";

export const KidEditPage = () => {
    const params = useParams();
    const currentUser = CurrentUser();
    const [isLoading, setLoading] = useState(true);
    const [kid, setKid] = useState(null);

    useEffect(() => {
        if (params.kidId) {
            AxiosInstance({
                url: "institutions/" + currentUser.institution.id + "/kids/" + params.kidId,
                method: "GET",
            })
                .then((response) => {
                    setKid(response.data);

                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                })
                .catch((error) => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                });
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <Dashboard contentClass={"kids"}>
            {isLoading ? <LoadingSpinner /> : kid ? <KidForm kid={kid} /> : <AccessDenied />}
        </Dashboard>
    );
};
