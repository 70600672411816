import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import AuthService from "services/AuthService";
import { useLocalStorage } from "hooks/useLocalStorage";
import { ByEmailStep1, ByEmailStep2, Step1, Step2 } from "./components";

export const Login = () => {
    const { t } = useTranslation();

    const [loginBy, setLoginBy] = useLocalStorage("loginBy", "phone", true);

    const [authenticated, setAuthenticated] = useState(null);
    const [isSubmitting, setSubmitting] = useState(false);
    const [step, setStep] = useState(1);
    const [data, setData] = useState({ phone: "", code: "" });

    useEffect(() => {
        if (AuthService.isToken()) {
            setAuthenticated(true);
        }
    }, []);

    // Handle fields change
    const handleChange = (input) => (e) => {
        let value = "";

        if ("code" === input) {
            value = e;
        } else {
            value = e.target.value;
        }

        setData((data) => {
            return {
                ...data,
                [input]: value,
            };
        });
    };

    const handleLoginBy = (by) => {
        if (by === "email") {
            setData({ email: "", password: "" });
        } else {
            setData({ phone: "", code: "" });
        }

        setStep(1);
        setLoginBy(by);
    };

    const nextStep = () => {
        setSubmitting(true);

        if (loginBy === "email") {
            if (1 === step) {
                AuthService.auth(data, "email").then(
                    (response) => {
                        if (response.status && response.status === true) {
                            setStep(2);
                        } else {
                            enqueueSnackbar(t(response.message, { ns: "common" }), {
                                variant: "success",
                            });
                        }

                        setTimeout(() => {
                            setSubmitting(false);
                        }, 300);
                    },
                    (error) => {
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 300);
                    }
                );
            } else if (2 === step) {
                AuthService.login(data, "email").then(
                    (response) => {
                        if (response.token) {
                            window.location.reload();
                        } else {
                            enqueueSnackbar(t(response.message, { ns: "common" }), {
                                variant: "success",
                            });
                        }

                        setTimeout(() => {
                            setSubmitting(false);
                        }, 300);
                    },
                    (error) => {
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 300);
                    }
                );
            }
        } else {
            if (1 === step) {
                AuthService.auth(data, "phone").then(
                    (response) => {
                        if (response.status && response.status === true) {
                            setStep(2);
                        } else {
                            enqueueSnackbar(t(response.message, { ns: "common" }), {
                                variant: "success",
                            });
                        }

                        setTimeout(() => {
                            setSubmitting(false);
                        }, 300);
                    },
                    (error) => {
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 300);
                    }
                );
            } else if (2 === step) {
                AuthService.login(data, "phone").then(
                    (response) => {
                        if (response.token) {
                            window.location.reload();
                        } else {
                            enqueueSnackbar(t(response.message, { ns: "common" }), {
                                variant: "success",
                            });
                        }

                        setTimeout(() => {
                            setSubmitting(false);
                        }, 300);
                    },
                    (error) => {
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 300);
                    }
                );
            }
        }
    };

    const reSendCodeOTP = () => {
        setSubmitting(true);

        if (loginBy === "email") {
            AuthService.passwordReset(data).then(
                (response) => {
                    enqueueSnackbar(t(response.message, { ns: "common" }), {
                        variant: "success",
                    });

                    setTimeout(() => {
                        setSubmitting(false);
                    }, 300);
                },
                (error) => {
                    setTimeout(() => {
                        setSubmitting(false);
                    }, 300);
                }
            );
        } else {
            AuthService.auth(data, "phone").then(
                (response) => {
                    if (response.status && response.status === true) {
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 300);
                    } else {
                        enqueueSnackbar(t(response.message, { ns: "common" }), {
                            variant: "success",
                        });

                        setTimeout(() => {
                            setSubmitting(false);
                        }, 300);
                    }
                },
                (error) => {
                    setTimeout(() => {
                        setSubmitting(false);
                    }, 300);
                }
            );
        }
    };

    const getSectionComponent = () => {
        if (loginBy === "email") {
            switch (step) {
                case 1:
                default:
                    return (
                        <ByEmailStep1
                            handleChange={handleChange}
                            handleLoginBy={handleLoginBy}
                            nextStep={nextStep}
                            isSubmitting={isSubmitting}
                        />
                    );
                case 2:
                    return (
                        <ByEmailStep2
                            handleChange={handleChange}
                            handleLoginBy={handleLoginBy}
                            nextStep={nextStep}
                            reSendCodeOTP={reSendCodeOTP}
                            isSubmitting={isSubmitting}
                        />
                    );
            }
        } else {
            switch (step) {
                case 1:
                default:
                    return (
                        <Step1
                            handleChange={handleChange}
                            handleLoginBy={handleLoginBy}
                            nextStep={nextStep}
                            isSubmitting={isSubmitting}
                        />
                    );
                case 2:
                    return (
                        <Step2
                            handleChange={handleChange}
                            handleLoginBy={handleLoginBy}
                            nextStep={nextStep}
                            reSendCodeOTP={reSendCodeOTP}
                            isSubmitting={isSubmitting}
                        />
                    );
            }
        }
    };

    if (authenticated) {
        return <Navigate to="/calendar" replace={true} />;
    } else {
        return getSectionComponent();
    }
};
