import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";

import AxiosInstance from "utils/AxiosInstance";
import CurrentUser from "services/CurrentUser";
import { useLexicons } from "hooks/useLexicons";
import { FormatDate, FormatMinutes, FormatTime } from "utils/Format";
import { Button, Modal } from "components/ui";
import { DatePicker, SelectField } from "components/forms";
import { SecureForm } from "components/PayU/SecureForm";

const today = dayjs();

export const ModalEnroll = ({ data, closeModal }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const lexicons = useLexicons();
    const currentUser = CurrentUser();

    const [card, setCard] = useState(null);
    const [enrollmentWhoPayOptions, setEnrollmentWhoPayOptions] = useState([]);
    const [billingMethodOptions, setBillingMethodOptions] = useState([]);
    const [paymentFrequencyOptions, setPaymentFrequencyOptions] = useState([]);
    const [availableDatesOptions, setAvailableDatesOptions] = useState([]);
    const [enrolled, setEnrolled] = useState(false);
    const [enrolledMessage, setEnrolledMessage] = useState(null);

    const defaultValues = {
        date: null,
        enrollmentWhoPay: null,
        billingMethod: null,
        paymentFrequency: null,
        card: card,
        ...data,
    };

    const validationSchema = Yup.object().shape({
        // paymentFrequency: Yup.object().required(
        //     t("The {{label}} field is required.", {
        //         ns: "validation",
        //         label: t("Frequency of payment", { ns: "common" }),
        //     })
        // ),
        // paymentFrequency: Yup.object().when("period", {
        //     is: (period) => period && period.value === "CYCLICAL",
        //     then: () =>
        //         Yup.object().required(
        //             t("The {{label}} field is required.", {
        //                 ns: "validation",
        //                 label: t("Frequency of payment", { ns: "common" }),
        //             })
        //         ),
        //     otherwise: () => Yup.array(),
        // }),
    });

    useEffect(() => {
        if (lexicons) {
            setEnrollmentWhoPayOptions(lexicons["enrollment"]["whoPay"]);
            setPaymentFrequencyOptions(lexicons["payment"]["frequency"]);
        }

        setAvailableDatesOptions(data.activity.date);

        let whoPay = [];
        data.activity.billingMethods.map((billingMethod) => {
            if (whoPay.some((e) => e.value === billingMethod.whoPay.value)) {
                return billingMethod;
            }
            return whoPay.push(billingMethod.whoPay);
        });

        setEnrollmentWhoPayOptions(whoPay);
    }, [data, lexicons]);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "enrollments/enroll",
            method: "POST",
            data: values,
        })
            .then((response) => {
                if (response.data.actionResponse.orderID && response.data.actionResponse.formContext) {
                    sessionStorage.setItem(
                        "payment_" + response.data.actionResponse.orderID,
                        JSON.stringify(response.data.actionResponse)
                    );
                }

                setEnrolled(true);
                setEnrolledMessage(response.data.message);

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);

                setTimeout(() => {
                    if (response.data.actionResponse.orderID && response.data.actionResponse.formContext) {
                        navigate("/payment/" + response.data.actionResponse.orderID);
                    }
                }, 1000);
            })
            .catch((error) => {
                if (error.response.data.message && error.response.data.message.indexOf("Invalid token")) {
                    setCard(null);
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    const handleSaveCard = (card) => {
        setCard(card);
    };

    return (
        <Modal handleClose={closeModal} title={t("Enroll in an activity", { ns: "common" })}>
            {enrolled ? (
                <>
                    <h3>{t(enrolledMessage, { ns: "server" })}</h3>
                </>
            ) : data.activity ? (
                <Formik
                    initialValues={defaultValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        handleSubmit(values, setSubmitting, resetForm);
                    }}
                    validateOnMount
                    enableReinitialize
                >
                    {({ handleSubmit, isSubmitting, isValid, setFieldValue, setFieldTouched, values }) => {
                        return (
                            <Form>
                                <div className="flex flex-col gap-4 w-full">
                                    <div className="flex flex-col gap-2">
                                        {data.kid ? (
                                            <div className="flex items-center gap-2">
                                                <strong>{t("Name of the kid", { ns: "common" })}:</strong>
                                                {data.kid.fullName}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Activity", { ns: "common" })}:</strong>
                                            {data.activity.name}
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Description", { ns: "common" })}:</strong>
                                            {data.activity.description}
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Brand", { ns: "common" })}:</strong>
                                            {data.activity.brand.name}
                                        </div>
                                        {data.activity.period.value === "SINGLE" && (
                                            <>
                                                <div className="flex items-center gap-2">
                                                    <strong>{t("Date", { ns: "common" })}:</strong>
                                                    <FormatDate date={data.activity.date.day} />
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <strong>{t("Hour", { ns: "common" })}:</strong>
                                                    <FormatTime
                                                        date={data.activity.date.time}
                                                        formatting={{ hour: "numeric", minute: "numeric" }}
                                                    />
                                                </div>
                                            </>
                                        )}
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Type of activity", { ns: "common" })}:</strong>
                                            {t(data.activity.period.name, { ns: "lexicons" })}
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Activity length", { ns: "common" })}:</strong>
                                            <FormatMinutes minutes={data.activity.activityLength} />
                                        </div>
                                    </div>

                                    {(currentUser != null && currentUser.accountType === "parent") ?? (
                                        <>
                                            {data.activity.period.value === "CYCLICAL" && card === null ? (
                                                <>
                                                    <div className="border-t"></div>
                                                    <SecureForm handleSaveCard={handleSaveCard} />
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    )}

                                    {data.activity.period.value === "CYCLICAL" && (
                                        <>
                                            <div className="border-t"></div>

                                            <Field
                                                component={SelectField}
                                                key={"date"}
                                                name={"date"}
                                                label={t("Available dates", { ns: "common" })}
                                                isMulti={true}
                                                required={true}
                                                fieldClassName={"col-span-2"}
                                                options={availableDatesOptions}
                                                getOptionLabel={(option) => {
                                                    return (
                                                        t(option.day.name, { ns: "lexicons" }) +
                                                        " - " +
                                                        dayjs(option.time).format("HH:mm")
                                                    );
                                                }}
                                            />
                                        </>
                                    )}

                                    {values.date &&
                                        values.date.length > 0 &&
                                        values.date.map((d, index) => {
                                            let weekDay = d.day.id - 1;
                                            let minDate = dayjs().weekday(weekDay);
                                            if (minDate < today) {
                                                minDate = minDate.add(7, "day");
                                            }

                                            let maxDate = minDate.add(5, "week");

                                            return (
                                                <Field
                                                    component={DatePicker}
                                                    key={"date" + index}
                                                    name={"date"}
                                                    label={
                                                        t("Date of first activity", { ns: "common" }) +
                                                        " - " +
                                                        t(d.day.name, { ns: "lexicons" }) +
                                                        " - " +
                                                        dayjs(d.time).format("HH:mm")
                                                    }
                                                    required={true}
                                                    defaultValue={minDate}
                                                    minDate={minDate}
                                                    maxDate={maxDate}
                                                    shouldDisableDate={(e) => {
                                                        const day = e.day() - 1;
                                                        return day !== weekDay;
                                                    }}
                                                    onChange={(value) => {
                                                        setFieldValue(
                                                            "date[" + index + "][firstActivity]",
                                                            dayjs(value).format()
                                                        );
                                                    }}
                                                />
                                            );
                                        })}

                                    {data.activity.forWhom.value === "INSTITUTION" ? (
                                        <>
                                            <Field
                                                component={SelectField}
                                                key={"enrollmentWhoPay"}
                                                name={"enrollmentWhoPay"}
                                                label={t("Who pays for the activity?", { ns: "common" })}
                                                required={true}
                                                fieldClassName={"col-span-2"}
                                                options={enrollmentWhoPayOptions}
                                                getOptionLabel={(option) => t(option.name, { ns: "lexicons" })}
                                                onChange={(event, value) => {
                                                    setFieldValue("enrollmentWhoPay", value ? value : "");
                                                    setFieldValue("billingMethod", null);

                                                    let bM = [];
                                                    if (value) {
                                                        values.activity.billingMethods.map((billingMethod) => {
                                                            return (
                                                                billingMethod.billingMethod.value !==
                                                                    "INSTITUTION_PAY_PER_KID_ACTIVITY" &&
                                                                billingMethod.whoPay.value === value.value &&
                                                                bM.push(billingMethod)
                                                            );
                                                        });
                                                    }

                                                    setBillingMethodOptions(bM);
                                                }}
                                            />
                                            {values.enrollmentWhoPay && (
                                                <>
                                                    <Field
                                                        component={SelectField}
                                                        key={"billingMethod"}
                                                        name={"billingMethod"}
                                                        label={t("Billing method", { ns: "common" })}
                                                        required={true}
                                                        fieldClassName={"col-span-2"}
                                                        options={billingMethodOptions}
                                                        getOptionLabel={(option) => {
                                                            return (
                                                                t(option.billingMethod.name, { ns: "lexicons" }) +
                                                                " - " +
                                                                t("{{price, currency(PLN)}}", { price: option.price })
                                                            );
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <Field
                                            component={SelectField}
                                            key={"paymentFrequency"}
                                            name={"paymentFrequency"}
                                            label={t("Frequency of payment", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-2"}
                                            options={paymentFrequencyOptions}
                                            getOptionLabel={(option) => t(option.name, { ns: "lexicons" })}
                                        />
                                    )}

                                    <div className="flex flex-row justify-center">
                                        {t("Do you want to enroll in a activity?", { ns: "common" })}
                                    </div>

                                    <div className="flex flex-row justify-center gap-2">
                                        <Button
                                            type={"submit"}
                                            className={"btn-primary"}
                                            onClick={handleSubmit}
                                            disabled={!isValid || isSubmitting}
                                        >
                                            {isSubmitting ? t("Saving", { ns: "button" }) : t("Yes", { ns: "button" })}
                                        </Button>
                                        <Button className={"btn-gray"} onClick={closeModal} disabled={isSubmitting}>
                                            {t("No", { ns: "button" })}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                t("No activity found", { ns: "common" })
            )}
        </Modal>
    );
};
