import { useTranslation } from "react-i18next";

export const AddressSchema = () => {
    const { t } = useTranslation();

    return [
        // {
        //     name: "name",
        //     label: t("Name", { ns: "common" }),
        //     componentType: "text",
        //     required: false,
        //     maxLength: 255,
        //     fieldClassName: "col-span-full md:col-span-3",
        // },
        {
            name: "addressLine1",
            label: t("Street", { ns: "common" }),
            componentType: "text",
            required: false,
            maxLength: 120,
            fieldClassName: "col-span-full md:col-span-4",
        },
        {
            name: "streetNumber",
            label: t("Number", { ns: "common" }),
            componentType: "text",
            required: false,
            maxLength: 10,
            fieldClassName: "col-span-3 md:col-span-1",
        },
        {
            name: "unitNumber",
            label: t("Premises number", { ns: "common" }),
            componentType: "text",
            required: false,
            maxLength: 10,
            fieldClassName: "col-span-3 md:col-span-1",
        },
        {
            name: "postalCode",
            label: t("Zip code", { ns: "common" }),
            componentType: "text",
            required: false,
            maxLength: 18,
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "city",
            label: t("City", { ns: "common" }),
            componentType: "text",
            required: false,
            maxLength: 180,
            fieldClassName: "col-span-full md:col-span-3",
        },
    ];
};
