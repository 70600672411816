import React from "react";

export const FormField = ({
    id,
    label,
    required,
    children,
    className,
    inline = false,
    labelPositionRight = false,
    formProps: { touched, errors },
}) => {
    const hasError = errors[id] && touched[id];

    return (
        <div
            className={"form-field " + (inline ? "!flex-row " : "") + className + (hasError ? " field-error" : "")}
            label={label}
        >
            {inline && labelPositionRight ? (
                <>
                    {children}
                    <label htmlFor={id} className="form-label">
                        {label}
                        {required && <sup className="required">*</sup>}
                    </label>
                </>
            ) : (
                <>
                    <label htmlFor={id} className="form-label">
                        {label}
                        {required && <sup className="required">*</sup>}
                    </label>
                    {children}
                </>
            )}

            {hasError && <small className="error">{errors[id]}</small>}
        </div>
    );
};
