import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Menu } from "@headlessui/react";
import { IoAddOutline, IoBasketballOutline, IoCreateOutline, IoEllipsisVerticalOutline } from "react-icons/io5";

import AxiosInstance from "utils/AxiosInstance";
import { FormatMinutes, FormatPrice } from "utils/Format";
import { LowestPrice } from "utils/LowestPrice";
import { LoadingSpinner } from "components/common";
import { Box, Button, ContentHeading } from "components/ui";
import ActivityModalSchedule from "./ActivityModalSchedule";
import ModalActivityDelete from "./ModalActivityDelete";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function ActivityList({ brandId }) {
    const { t } = useTranslation();

    const [modal, setModal] = useState({ show: false, data: null, type: null });

    const [citiesLoading, setCitiesLoading] = useState(true);
    const [activitiesLoading, setActivitiesLoading] = useState(true);
    const [activeCity, setActiveCity] = useState(0);
    const [cities, setCities] = useState([]);
    const [activeActivity, setActiveActivity] = useState(0);
    const [activities, setActivities] = useState([]);

    useEffect(() => {
        handleLoadData();
    }, [brandId]);

    const handleLoadData = () => {
        AxiosInstance({
            url: "brands/" + brandId + "/cities",
            method: "GET",
        }).then((response) => {
            setCities(response.data);

            setTimeout(() => {
                setCitiesLoading(false);

                if (response.data.length) {
                    loadActivities(response.data[0].id);
                } else {
                    setActivitiesLoading(false);
                }
            }, 300);
        });
    };

    function loadActivities(city) {
        setActiveCity(city);
        setActivitiesLoading(true);
        AxiosInstance({
            url: "brands/" + brandId + "/cities/" + city + "/activities",
            method: "GET",
        }).then((response) => {
            setActivities(response.data);

            setTimeout(() => {
                setActivitiesLoading(false);
            }, 300);
        });
    }

    const openModal = (activityId, type = "view") => {
        setModal({ show: true, data: { brandId: brandId, activityId: activityId }, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
    };

    return (
        <>
            <div className="activities flex flex-col gap-6">
                <ContentHeading
                    showBtn={true}
                    btnUrl={"/brand/brands/" + brandId + "/activities/new"}
                    btnIcon={<IoAddOutline size={"1.125em"} />}
                    btnLabel={t("Add activity", { ns: "button" })}
                >
                    <IoBasketballOutline size={"1.25rem"} />
                    {t("Activity offer", { ns: "common" })}
                </ContentHeading>

                {citiesLoading ? (
                    <LoadingSpinner />
                ) : activities.length ? (
                    <div className="content-links">
                        {cities.map((city, index) => (
                            <Button
                                className={city.id === activeCity ? "btn-primary" : "btn-dark-outline btn-opacity"}
                                onClick={() => loadActivities(city.id)}
                                key={index}
                            >
                                {city.name}
                            </Button>
                        ))}
                    </div>
                ) : (
                    ""
                )}

                {activitiesLoading ? (
                    <LoadingSpinner />
                ) : activities.length ? (
                    <div className="flex flex-col md:flex-row gap-6">
                        <div className="flex flex-col gap-4 w-full md:w-3/12">
                            {activities.map((activity, index) => (
                                <Button
                                    className={
                                        index === activeActivity ? "btn-primary" : "btn-dark-outline btn-opacity"
                                    }
                                    onClick={() => setActiveActivity(index)}
                                    key={index}
                                >
                                    {activity.name}
                                </Button>
                            ))}
                        </div>

                        {activities.map((activity, index) => (
                            <Box
                                className={
                                    "box-items relative w-full md:w-9/12 " + (index === activeActivity ? "" : "!hidden")
                                }
                                key={index}
                            >
                                <div className="flex gap-2 absolute top-4 right-4">
                                    <Link to={"/brand/brands/" + brandId + "/activities/" + activity.id + "/edit"}>
                                        <IoCreateOutline size={"1.125em"} />
                                    </Link>

                                    <Menu as={"div"} className={"relative"}>
                                        <Menu.Button>
                                            <IoEllipsisVerticalOutline size={"1.125em"} />
                                        </Menu.Button>
                                        <Menu.Items
                                            className={
                                                "absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            }
                                        >
                                            {activity.period.value === "CYCLICAL" && (
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            type="button"
                                                            className={classNames(
                                                                active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                                                "block w-full px-4 py-2 text-sm text-left"
                                                            )}
                                                            onClick={() => openModal(activity.id, "schedule")}
                                                        >
                                                            {t("Schedule", { ns: "button" })}
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            )}
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        type="button"
                                                        className={classNames(
                                                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                                            "block w-full px-4 py-2 text-sm text-left"
                                                        )}
                                                        onClick={() => openModal(activity.id, "removeActivity")}
                                                    >
                                                        {t("Remove activity", { ns: "button" })}
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Menu>
                                </div>

                                <h3>{activity.name}</h3>

                                <div className="box-item">{activity.description}</div>
                                <hr></hr>
                                <div className="box-item">
                                    <strong>{t("Territorial scope", { ns: "common" })}:</strong> {activity.territory}
                                </div>
                                <div className="box-item">
                                    <strong>{t("Price from", { ns: "common" })}:</strong>
                                    <FormatPrice price={LowestPrice(activity.billingMethods)} />
                                </div>
                                <div className="box-item">
                                    <strong>{t("Activity length", { ns: "common" })}:</strong>
                                    <FormatMinutes minutes={activity.activityLength} />
                                </div>
                            </Box>
                        ))}
                    </div>
                ) : (
                    t("No activities found", { ns: "common" })
                )}
            </div>

            {modal.show && modal.data && modal.type === "schedule" && (
                <ActivityModalSchedule data={modal.data} closeModal={handleModalClose} />
            )}

            {modal.show && modal.data && modal.type === "removeActivity" && (
                <ModalActivityDelete data={modal.data} closeModal={handleModalClose} handleLoadData={handleLoadData} />
            )}
        </>
    );
}

export default ActivityList;
