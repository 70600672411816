import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { LanguageSwitcher } from "components/ui";
import { Logo } from "components/common/Logo";

export const Layout = ({ signUpImg, children }) => {
    const theme = useTheme();
    const hidden = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <div className="flex items-stretch min-h-screen p-8 bg-primary">
            <div className="flex items-center h-auto xl:max-w-6xl gap-8 m-auto">
                <div className="flex flex-col h-full w-full md:w-6/12 lg:w-5/12 xl:p-12">
                    {hidden ? (
                        <div className="mx-auto">
                            <Logo />
                        </div>
                    ) : null}
                    <div className="max-w-96 m-auto">
                        <div className="flex flex-col gap-6">{children}</div>
                        <div className="mt-6">
                            <LanguageSwitcher />
                        </div>
                    </div>
                </div>
                {!hidden ? <div className="flex md:w-6/12 lg:w-7/12 h-full lg:p-8">{signUpImg}</div> : null}
            </div>
        </div>
    );
};
