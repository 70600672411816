import React from "react";
import { useTranslation } from "react-i18next";
import {
    IoBusinessOutline,
    IoCallOutline,
    IoLocationOutline,
    IoMailOutline,
    IoPersonOutline,
    IoShieldOutline,
    IoTimeOutline,
} from "react-icons/io5";

import { Box, BoxItem } from "components/ui";
import { FormatTime } from "utils/Format";

function BoxInstitutionInformation({ institution }) {
    const { t } = useTranslation();

    return (
        <>
            <Box
                className={"box-items w-full md:w-7/12 xl:w-4/6"}
                title={t("Institution information", { ns: "common" })}
            >
                <BoxItem icon={<IoShieldOutline size={"1.25em"} />} label={t("Institution name", { ns: "common" })}>
                    {institution.name}
                </BoxItem>
                <BoxItem icon={<IoBusinessOutline size={"1.25rem"} />} label={t("Institution type", { ns: "common" })}>
                    {institution.institutionType ? institution.institutionType.name : ""}
                </BoxItem>
                <BoxItem icon={<IoLocationOutline size={"1.25em"} />} label={t("Address", { ns: "common" })}>
                    {institution.street} {institution.number} {institution.premises}, {institution.zipCode}{" "}
                    {institution.city}
                </BoxItem>
                <BoxItem icon={<IoCallOutline size={"1.25em"} />} label={t("Phone", { ns: "common" })}>
                    {institution.phone}
                </BoxItem>
                <BoxItem icon={<IoMailOutline size={"1.25em"} />} label={t("E-mail", { ns: "common" })}>
                    {institution.email}
                </BoxItem>
                <BoxItem icon={<IoTimeOutline size={"1.25em"} />} label={t("Opening hours", { ns: "common" })}>
                    <FormatTime date={institution.openTime} formatting={{ hour: "numeric", minute: "numeric" }} />
                    {" - "}
                    <FormatTime date={institution.closeTime} formatting={{ hour: "numeric", minute: "numeric" }} />
                </BoxItem>
                <BoxItem icon={<IoPersonOutline size={"1.25em"} />} label={t("Director", { ns: "common" })}>
                    {institution.directorName}
                </BoxItem>
            </Box>
        </>
    );
}

export default BoxInstitutionInformation;
