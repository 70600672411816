import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";

import { Box } from "../ui/Box";
import { AddEventSidebar, Calendar, EventPopUp, SidebarLeft } from "components/calendar";
import { LoadingSpinner } from "components/common";
import AxiosInstance from "utils/AxiosInstance";

export const AppCalendar = () => {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [isReLoading, setReLoading] = useState(false);
    const [store, setStore] = useState(null);
    const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
    const [eventPopUpOpen, setEventPopUpOpen] = useState(false);
    const [addEventSidebarOpen, setAddEventSidebarOpen] = useState(false);

    const leftSidebarWidth = 300;
    const addEventSidebarWidth = 400;
    const mdAbove = useMediaQuery("(min-width:992px)");

    const generalCalendarName = t("General calendar", { ns: "common" });

    const handleLeftSidebarToggle = () => setLeftSidebarOpen(!leftSidebarOpen);
    const handleEventPopUpToggle = () => setEventPopUpOpen(!eventPopUpOpen);
    const handleAddEventSidebarToggle = () => setAddEventSidebarOpen(!addEventSidebarOpen);

    async function reloadCalendars(selectedCalendars) {
        setReLoading(true);

        AxiosInstance({
            url: "calendars/by-selected-calendars/" + generalCalendarName,
            method: "POST",
            data: selectedCalendars,
        }).then((response) => {
            setStore(response.data);

            setTimeout(() => {
                setReLoading(false);
            }, 300);
        });
    }

    useEffect(() => {
        AxiosInstance({
            url: "calendars/" + generalCalendarName,
            method: "GET",
        }).then((response) => {
            setStore(response.data);

            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, [generalCalendarName]);

    const handleCalendarsUpdate = (key) => {
        const currentSelectedCalendars = store.selectedCalendars;
        const filterIndex = currentSelectedCalendars.findIndex((i) => i === key);

        if (currentSelectedCalendars.includes(key)) {
            currentSelectedCalendars.splice(filterIndex, 1);
        } else {
            currentSelectedCalendars.push(key);
        }

        if (currentSelectedCalendars.length === 0) {
            currentSelectedCalendars.length = 0;
        }

        reloadCalendars(currentSelectedCalendars);
    };

    return (
        <Box className={"app-calendar relative"}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <SidebarLeft
                        store={store}
                        mdAbove={mdAbove}
                        leftSidebarOpen={leftSidebarOpen}
                        leftSidebarWidth={leftSidebarWidth}
                        handleCalendarsUpdate={handleCalendarsUpdate}
                        handleLeftSidebarToggle={handleLeftSidebarToggle}
                    />

                    {isReLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <Calendar
                            store={store}
                            mdAbove={mdAbove}
                            leftSidebarOpen={leftSidebarOpen}
                            leftSidebarWidth={leftSidebarWidth}
                            handleLeftSidebarToggle={handleLeftSidebarToggle}
                            handleEventPopUpToggle={handleEventPopUpToggle}
                            handleAddEventSidebarToggle={handleAddEventSidebarToggle}
                        />
                    )}

                    <EventPopUp
                        store={store}
                        eventPopUpOpen={eventPopUpOpen}
                        handleEventPopUpToggle={handleEventPopUpToggle}
                    />

                    <AddEventSidebar
                        store={store}
                        addEventSidebarOpen={addEventSidebarOpen}
                        addEventSidebarWidth={addEventSidebarWidth}
                        handleAddEventSidebarToggle={handleAddEventSidebarToggle}
                    />
                </>
            )}
        </Box>
    );
};
