import React from "react";

import { Dashboard } from "components/common";
import { AppCalendar } from "components/layout/AppCalendar";

export const CalendarPage = () => {
    return (
        <Dashboard contentClass={"calendar"}>
            <AppCalendar />
        </Dashboard>
    );
};
