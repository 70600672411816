import React from "react";
import { useTranslation } from "react-i18next";

export const RoleBadge = ({ role, classNames = "" }) => {
    const { t } = useTranslation("roles");
    let type = "";
    let label = "";

    switch (role) {
        case "ROLE_PROVIDER_ADMIN":
        case "ROLE_INSTITUTION_ADMIN":
            type = "primary";
            label = "admin";
            break;
        case "ROLE_PROVIDER_TRAINER":
        case "ROLE_INSTITUTION_TEACHER":
            type = "warning";
            label = "trainer";
            break;
    }

    return <div className={"badge badge-" + type + " " + classNames}>{t("short." + role, { ns: "roles" })}</div>;
};
