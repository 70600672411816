import React from "react";
import { useTranslation } from "react-i18next";

import { Button, ImgWithFallback } from "components/ui";
import { Layout } from "./Layout";

import signUpWebP from "../img/signup-step-1.webp";
import signUpPNG from "../img/signup-step-1.png";

export const Step3 = ({
    handleChange,
    nextStep,
    resetSignUp,
    reSendCodeOTP,
    loginBy,
    isSubmitting,
    data,
    passwordValidate,
    error,
}) => {
    const { t } = useTranslation();

    const signUpImg = (
        <ImgWithFallback src={signUpWebP} fallback={signUpPNG} className="flex max-w-xl m-auto" alt="Rejestracja" />
    );

    return (
        <Layout signUpImg={signUpImg}>
            <h3>{t("Password", { ns: "common" })}</h3>
            <p className="mb-0">
                {t("Set a password to log in using email.", {
                    ns: "common",
                })}
            </p>

            <div className="flex flex-col w-full gap-2">
                <div className="form-field">
                    <label htmlFor="password" className="form-label">
                        {t("Password", { ns: "common" })}
                    </label>
                    <input
                        type="password"
                        name="password"
                        id="password"
                        className="form-input"
                        autoComplete="false"
                        minLength={8}
                        maxLength={64}
                        onChange={handleChange("password")}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") nextStep();
                        }}
                        value={data.password}
                    />
                    {passwordValidate && data.password ? (
                        <>
                            <progress
                                hidden={data.password.length === 0}
                                className={`password-strength strength-${passwordValidate.strength}`}
                                value={passwordValidate.strength}
                                max="4"
                            />
                            <div
                                className={`password-feedback strength-${passwordValidate.strength}`}
                                hidden={data.password.length === 0}
                            >
                                {passwordValidate.feedback}
                            </div>
                        </>
                    ) : null}
                </div>
                <div className="form-field">
                    <label htmlFor="confirmPassword" className="form-label">
                        {t("Confirmation Password", { ns: "common" })}
                    </label>
                    <input
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        className="form-input"
                        minLength={8}
                        maxLength={64}
                        onChange={handleChange("confirmPassword")}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") nextStep();
                        }}
                        value={data.confirmPassword}
                    />
                </div>
            </div>

            <Button className={"btn-black w-full"} onClick={nextStep} disabled={isSubmitting}>
                {isSubmitting ? t("Sending", { ns: "button" }) : t("Next", { ns: "button" })}
            </Button>
        </Layout>
    );
};
