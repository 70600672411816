import { useTranslation } from "react-i18next";
import { FormatDate, FormatTime } from "./Format";

export const DisplayDayTime = ({ day, time }) => {
    const { t } = useTranslation();

    return (
        <>
            {t(day, { ns: "lexicons" })} -{" "}
            <FormatTime date={time} formatting={{ hour: "numeric", minute: "numeric" }} />
        </>
    );
};

export const DisplayDateTime = ({ date, time }) => {
    return (
        <>
            <FormatDate date={date} />
            {" - "}
            <FormatTime date={time} formatting={{ hour: "numeric", minute: "numeric" }} />
        </>
    );
};
