import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui";

export const ButtonSignUpByPhone = ({ handleSignUpBy, type }) => {
    const { t } = useTranslation();

    return (
        <Button
            className={(type === 3 ? "text-black" : "text-primary") + " mx-auto !py-1"}
            onClick={() => handleSignUpBy("phone")}
        >
            {t("Register by phone", { ns: "common" })}
        </Button>
    );
};
