import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IoWalletOutline } from "react-icons/io5";

import AxiosInstance from "utils/AxiosInstance";
import { FormatDate, FormatPrice } from "utils/Format";
import { DataGrid } from "components/grid";
import { LoadingSpinner } from "components/common";
import { Button, ContentHeading, OptionMenu } from "components/ui";
import ModalBillingPayments from "./ModalBillingPayments";

function BillingList({ brands }) {
    const { t } = useTranslation();

    const [activeBrand, setActiveBrand] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [billings, setBillings] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [modal, setModal] = useState({ show: false, data: null });

    const columns = [
        {
            field: "whoPay",
            headerName: t("Who pay", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => {
                if (row.type.value === "PARENT_PAY_BRAND") {
                    return row.whoPays.fullName;
                } else if (row.type.value === "INSTITUTION_PAY_BRAND") {
                    return row.institution.name;
                }

                return "---";
            },
        },
        {
            field: "kid",
            headerName: t("Kid", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${(value && value.fullName) || "---"}`,
        },
        {
            field: "institution",
            headerName: t("Institution", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${(value && value.name) || "---"}`,
        },
        {
            field: "activities",
            headerName: t("Activities", { ns: "common" }),
            flex: 1,
            minWidth: 320,
            valueGetter: (value, row) => {
                return row.activity
                    .map((a) => {
                        return a.name;
                    })
                    .join(", ");
            },
        },
        // {
        //     field: "status",
        //     headerName: t("Status", { ns: "common" }),
        //     width: 180,
        //     renderCell: (params) => {
        //         let color = "";
        //         let title = "";

        //         switch (params.row.nextPayment.status.value) {
        //             case "OPEN":
        //                 color = "#0284c7";
        //                 title = "Inactive";
        //                 break;
        //             case "ACTIVE":
        //             case "ENROLLED":
        //                 color = "#166534";
        //                 break;
        //             case "CANCELLED":
        //                 color = "#313333";
        //                 break;
        //             default:
        //                 // color = "#ca8a04";
        //                 color = "#dc2626";
        //                 break;
        //         }

        //         return <Badge color={color}>{title}</Badge>;
        //         // return <Badge color={color}>{t(params.row.nextPayment.status.name, { ns: "lexicons" })}</Badge>;
        //     },
        // },
        {
            field: "amount",
            headerName: t("Amount fee", { ns: "common" }),
            width: 120,
            sortable: false,
            renderCell: (params) => {
                return <FormatPrice price={params.row.nextPayment.amount} />;
            },
        },
        {
            field: "date",
            headerName: t("Next pay", { ns: "common" }),
            width: 150,
            sortable: false,
            renderCell: (params) => {
                return <FormatDate date={params.row.nextPay} />;
            },
        },
        {
            field: "actions",
            headerName: "",
            align: "right",
            sortable: false,
            width: 120,
            renderCell: (params) => {
                return (
                    <OptionMenu
                        options={[
                            {
                                text: t("Payments", { ns: "button" }),
                                icon: <IoWalletOutline size={"1.25em"} />,
                                menuItemProps: {
                                    onClick: () => openModal(params.row, "payments"),
                                },
                            },
                        ]}
                    />
                );
            },
        },
    ];

    useEffect(() => {
        setBillings([]);

        if (brands.length) {
            setLoading(true);

            AxiosInstance({
                url: "billings/brand/" + brands[activeBrand].id,
                method: "GET",
            })
                .then((response) => {
                    setBillings(response.data);

                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                })
                .catch((error) => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                });
        } else {
            setLoading(false);
        }
    }, [activeBrand, searchParams]);

    const openModal = (data, type = "view") => {
        setModal({ show: true, data: data, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
    };

    return (
        <>
            <ContentHeading tag="h3">
                <IoWalletOutline size={"1.5rem"} />
                {t("Billings", { ns: "common" })}
            </ContentHeading>

            {brands.length > 1 && (
                <div className="content-links">
                    {brands.map((brand, index) => (
                        <Button
                            className={index === activeBrand ? "btn-primary" : "btn-dark-outline btn-opacity"}
                            onClick={() => setActiveBrand(index)}
                            key={index}
                        >
                            {brand.name}
                        </Button>
                    ))}
                </div>
            )}

            {isLoading ? <LoadingSpinner /> : <DataGrid rows={billings} columns={columns} enableSearch={true} />}

            {modal.show && modal.data && modal.type === "payments" && (
                <ModalBillingPayments billing={modal.data} closeModal={handleModalClose} />
            )}
        </>
    );
}

export default BillingList;
