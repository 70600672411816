import React from "react";
import { useTranslation } from "react-i18next";
import { IoArrowRedoOutline, IoCodeOutline, IoHomeOutline, IoRibbonOutline } from "react-icons/io5";

import { Box, BoxItem } from "components/ui";

function BoxInstitutionMoreInfo({ institution }) {
    const { t } = useTranslation();

    return (
        <>
            <Box
                className={"box-items w-full md:w-5/12 xl:w-2/6"}
                title={t("Additional information", { ns: "common" })}
            >
                {institution.code && (
                    <BoxItem icon={<IoCodeOutline size={"1.25em"} />} label={t("Institution code", { ns: "common" })}>
                        {institution.code}
                    </BoxItem>
                )}
                <BoxItem
                    icon={<IoHomeOutline size={"1.25em"} />}
                    label={t("Entrance to the institution", { ns: "common" })}
                >
                    {institution.entryDescription ? institution.entryDescription : "---"}
                </BoxItem>
                <BoxItem
                    icon={<IoArrowRedoOutline size={"1.25em"} />}
                    label={t("Transferring children to teachers/coaches", { ns: "common" })}
                >
                    {institution.otherDescription ? institution.otherDescription : "---"}
                </BoxItem>
                <BoxItem
                    icon={<IoRibbonOutline size={"1.25em"} />}
                    label={t("Institution description", { ns: "common" })}
                >
                    {institution.institutionDescription ? institution.institutionDescription : "---"}
                </BoxItem>
            </Box>
        </>
    );
}

export default BoxInstitutionMoreInfo;
