import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";
import TeacherForm from "./components/TeacherForm";

export const TeacherEditPage = () => {
    const params = useParams();
    const [isLoading, setLoading] = useState(true);
    const [teacher, setTeacher] = useState(null);

    useEffect(() => {
        if (params.teacherId) {
            AxiosInstance({
                url: "teachers/" + params.teacherId,
                method: "GET",
            })
                .then((response) => {
                    setTeacher(response.data);

                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                })
                .catch((error) => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                });
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <Dashboard contentClass={"teachers"}>
            {isLoading ? <LoadingSpinner /> : teacher ? <TeacherForm teacher={teacher} /> : <AccessDenied />}
        </Dashboard>
    );
};
