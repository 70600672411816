import React from "react";
import { FormField } from "./FormField";

export const Checkbox = ({
    label,
    className = "",
    fieldClassName,
    inline = false,
    labelPositionRight = false,
    field: { name, value, ...fieldProps },
    form,
    required,
    ...props
}) => {
    return (
        <FormField
            id={name}
            label={label}
            required={required}
            className={"form-field-checkbox " + fieldClassName}
            inline={inline}
            labelPositionRight={labelPositionRight}
            formProps={form}
        >
            <input
                className={"form-input form-input-checkbox " + className}
                type="checkbox"
                id={name}
                name={name}
                checked={!!value}
                {...fieldProps}
                {...props}
            />
        </FormField>
    );
};
