import React, { createElement } from "react";

import {
    IoAccessibilityOutline,
    IoAirplaneOutline,
    IoAlarmOutline,
    IoAmericanFootballOutline,
    IoApertureOutline,
    IoBalloonOutline,
    IoBarbellOutline,
    IoBaseballOutline,
    IoBasketballOutline,
    IoBicycleOutline,
    IoBoatOutline,
    IoBonfireOutline,
    IoBookOutline,
    IoBowlingBallOutline,
    IoBrushOutline,
    IoBugOutline,
    IoBulbOutline,
    IoBusOutline,
    IoCameraOutline,
    IoCarOutline,
    IoCarSportOutline,
    IoCogOutline,
    IoColorFillOutline,
    IoColorFilterOutline,
    IoColorPaletteOutline,
    IoColorWandOutline,
    IoCompassOutline,
    IoCubeOutline,
    IoCutOutline,
    IoDiamondOutline,
    IoDiceOutline,
    IoEarthOutline,
    IoEggOutline,
    IoExtensionPuzzleOutline,
    IoFilmOutline,
    IoFishOutline,
    IoFlameOutline,
    IoFlaskOutline,
    IoFlowerOutline,
    IoFootballOutline,
    IoFootstepsOutline,
    IoGameControllerOutline,
    IoGlobeOutline,
    IoGolfOutline,
    IoHappyOutline,
    IoHeartOutline,
    IoIceCreamOutline,
    IoLeafOutline,
    IoLibraryOutline,
    IoMedicalOutline,
    IoMoonOutline,
    IoMusicalNotesOutline,
    IoPartlySunnyOutline,
    IoPawOutline,
    IoPlanetOutline,
    IoPrismOutline,
    IoRainyOutline,
    IoRocketOutline,
    IoRoseOutline,
    IoSchoolOutline,
    IoShapesOutline,
    IoStarOutline,
    IoSunnyOutline,
    IoTelescopeOutline,
    IoTennisballOutline,
    IoTrainOutline,
    IoTrophyOutline,
} from "react-icons/io5";

export const Icons = {
    IoAccessibilityOutline: IoAccessibilityOutline,
    IoAirplaneOutline: IoAirplaneOutline,
    IoAlarmOutline: IoAlarmOutline,
    IoAmericanFootballOutline: IoAmericanFootballOutline,
    IoApertureOutline: IoApertureOutline,
    IoBalloonOutline: IoBalloonOutline,
    IoBarbellOutline: IoBarbellOutline,
    IoBaseballOutline: IoBaseballOutline,
    IoBasketballOutline: IoBasketballOutline,
    IoBicycleOutline: IoBicycleOutline,
    IoBoatOutline: IoBoatOutline,
    IoBonfireOutline: IoBonfireOutline,
    IoBookOutline: IoBookOutline,
    IoBowlingBallOutline: IoBowlingBallOutline,
    IoBrushOutline: IoBrushOutline,
    IoBugOutline: IoBugOutline,
    IoBulbOutline: IoBulbOutline,
    IoBusOutline: IoBusOutline,
    IoCameraOutline: IoCameraOutline,
    IoCarOutline: IoCarOutline,
    IoCarSportOutline: IoCarSportOutline,
    IoCogOutline: IoCogOutline,
    IoColorFillOutline: IoColorFillOutline,
    IoColorFilterOutline: IoColorFilterOutline,
    IoColorPaletteOutline: IoColorPaletteOutline,
    IoColorWandOutline: IoColorWandOutline,
    IoCompassOutline: IoCompassOutline,
    IoCubeOutline: IoCubeOutline,
    IoCutOutline: IoCutOutline,
    IoDiamondOutline: IoDiamondOutline,
    IoDiceOutline: IoDiceOutline,
    IoEarthOutline: IoEarthOutline,
    IoEggOutline: IoEggOutline,
    IoExtensionPuzzleOutline: IoExtensionPuzzleOutline,
    IoFilmOutline: IoFilmOutline,
    IoFishOutline: IoFishOutline,
    IoFlameOutline: IoFlameOutline,
    IoFlaskOutline: IoFlaskOutline,
    IoFlowerOutline: IoFlowerOutline,
    IoFootballOutline: IoFootballOutline,
    IoFootstepsOutline: IoFootstepsOutline,
    IoGameControllerOutline: IoGameControllerOutline,
    IoGlobeOutline: IoGlobeOutline,
    IoGolfOutline: IoGolfOutline,
    IoHappyOutline: IoHappyOutline,
    IoHeartOutline: IoHeartOutline,
    IoIceCreamOutline: IoIceCreamOutline,
    IoLeafOutline: IoLeafOutline,
    IoLibraryOutline: IoLibraryOutline,
    IoMedicalOutline: IoMedicalOutline,
    IoMoonOutline: IoMoonOutline,
    IoMusicalNotesOutline: IoMusicalNotesOutline,
    IoPartlySunnyOutline: IoPartlySunnyOutline,
    IoPawOutline: IoPawOutline,
    IoPlanetOutline: IoPlanetOutline,
    IoPrismOutline: IoPrismOutline,
    IoRainyOutline: IoRainyOutline,
    IoRocketOutline: IoRocketOutline,
    IoRoseOutline: IoRoseOutline,
    IoSchoolOutline: IoSchoolOutline,
    IoShapesOutline: IoShapesOutline,
    IoStarOutline: IoStarOutline,
    IoSunnyOutline: IoSunnyOutline,
    IoTelescopeOutline: IoTelescopeOutline,
    IoTennisballOutline: IoTennisballOutline,
    IoTrainOutline: IoTrainOutline,
    IoTrophyOutline: IoTrophyOutline,
};

export const IconComponent = ({ icon, size }) => {
    if (typeof Icons[icon] !== "undefined") {
        return createElement(Icons[icon], {
            size: size,
        });
    }

    return React.createElement(() => (
        <div>
            The component <strong>{icon}</strong> has not been created yet.
        </div>
    ));
};
