import React from "react";

export const useLocalStorage = (storageKey, fallbackState, isString = false) => {
    const [value, setValue] = React.useState(
        (isString ? localStorage.getItem(storageKey) : JSON.parse(localStorage.getItem(storageKey))) ?? fallbackState
    );

    React.useEffect(() => {
        localStorage.setItem(storageKey, isString ? value : JSON.stringify(value));
    }, [value, storageKey, isString]);

    return [value, setValue];
};
