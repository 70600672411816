import React from "react";
import { Link } from "react-router-dom";

export const ContentHeading = ({
    children,
    tag = "h5",
    className = "",
    showBtn = false,
    btnUrl,
    btnClassName = "btn-primary",
    btnIcon,
    btnLabel,
}) => {
    const headingTag = (tag) => {
        switch (tag) {
            case "h1":
                return <h1 className="flex items-center gap-3">{children}</h1>;
            case "h2":
                return <h2 className="flex items-center gap-3">{children}</h2>;
            case "h3":
                return <h3 className="flex items-center gap-3">{children}</h3>;
            case "h4":
                return <h4 className="flex items-center gap-3">{children}</h4>;
            case "h5":
                return <h5 className="flex items-center gap-3">{children}</h5>;
            case "h6":
                return <h6 className="flex items-center gap-3">{children}</h6>;
        }
    };

    return (
        <div className={"content-heading " + className}>
            {headingTag(tag)}
            {showBtn && (
                <Link to={btnUrl} className={"btn " + btnClassName}>
                    {btnIcon && btnIcon}
                    {btnLabel}
                </Link>
            )}
        </div>
    );
};
