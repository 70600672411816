import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AuthHeader from "services/AuthHeader";
import AuthService from "services/AuthService";

const AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 5000,
    headers: AuthHeader(),
});

const AxiosInterceptor = ({ children }) => {
    const { t } = useTranslation();

    const [isSet, setIsSet] = useState(false);

    useEffect(() => {
        const resInterceptor = (response) => {
            return response;
        };

        const errInterceptor = (error) => {
            if (error.code === "ERR_BAD_REQUEST" && error.response.status === 401 && error.config.url !== "login") {
                AuthService.logout();
                window.location.reload();
            } else {
                if (error.request.responseType === "blob" && error.response.data instanceof Blob) {
                    return Promise.reject(error);
                }

                let message = "";
                if (error.response && Object.keys(error.response.data).length > 0) {
                    message = error.response.data.detail ? error.response.data.detail : error.response.data.message;
                } else {
                    message = error.message;
                }

                if (Array.isArray(message)) {
                    message.forEach((m) => {
                        enqueueSnackbar(t(m, { ns: "server" }), {
                            variant: "error",
                            anchorOrigin: { vertical: "bottom", horizontal: "right" },
                        });
                    });
                } else {
                    enqueueSnackbar(t(message, { ns: "server" }), {
                        variant: "error",
                        anchorOrigin: { vertical: "bottom", horizontal: "right" },
                    });
                }
            }

            return Promise.reject(error);
        };

        const interceptor = AxiosInstance.interceptors.response.use(resInterceptor, errInterceptor);

        setIsSet(true);

        return () => AxiosInstance.interceptors.response.eject(interceptor);
    }, [t, isSet]);

    return isSet && children;
};

export default AxiosInstance;
export { AxiosInterceptor };
