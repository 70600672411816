import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { IoAddOutline, IoTrashOutline } from "react-icons/io5";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { LoadingSpinner } from "components/common";
import { Button, Modal } from "components/ui";

function TeacherModalGroup({ data, closeModal, handleLoadData }) {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [teacher, setTeacher] = useState(null);
    const [teacherGroup, setTeacherGroup] = useState([]);
    const [groupsOptions, setGroupsOptions] = useState([]);
    const [teacherTypesOptions, setTeacherTypesOptions] = useState([]);

    useEffect(() => {
        Promise.all([
            AxiosInstance({
                url: "/teachers/" + data.teacherId,
                method: "GET",
            }).then((response) => {
                setTeacher(response.data);
                setTeacherGroup(response.data.groups);
            }),

            AxiosInstance({
                url: "institutions/" + currentUser.institution.id + "/groups",
                method: "GET",
            }).then((response) => {
                setGroupsOptions(response.data);
            }),

            AxiosInstance({
                url: "teacher-types",
                method: "GET",
            }).then((response) => {
                setTeacherTypesOptions(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, []);

    const handleAddGroup = () => {
        setTeacherGroup([...teacherGroup, { groupId: null, groupName: null, typeId: null, typeName: null }]);
    };

    const handleDeleteGroup = (idx) => {
        setTeacherGroup(
            teacherGroup.filter((group, i) => {
                return i !== idx;
            })
        );
    };

    const handleUpdateGroup = (group, e) => {
        const idx = e.name;

        setTeacherGroup(
            teacherGroup.map((item, i) => {
                if (i === idx) {
                    return { ...item, groupId: group.id, groupName: group.name };
                } else {
                    return item;
                }
            })
        );
    };

    const handleUpdateType = (type, e) => {
        const idx = e.name;

        setTeacherGroup(
            teacherGroup.map((item, i) => {
                if (i === idx) {
                    return { ...item, typeId: type.id, typeName: type.name };
                } else {
                    return item;
                }
            })
        );
    };

    const handleSubmit = async () => {
        setSubmitting(true);

        AxiosInstance({
            url: "teachers/" + teacher.id + "/groups",
            method: "PUT",
            data: teacherGroup,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (response.status === 201) {
                    handleLoadData();
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Teacher groups", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className="flex flex-col gap-4 w-full">
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                            <strong>{t("Full name", { ns: "common" })}:</strong>
                            {teacher.fullName}
                        </div>
                    </div>

                    <div className="border-t"></div>

                    <div className="flex">
                        <strong>{t("Group and role of the teacher", { ns: "common" })}</strong>
                    </div>

                    {teacherGroup ? (
                        <div className="flex flex-col gap-2">
                            <div className="grid grid-cols-11 gap-4">
                                <div className={"form-field col-span-5"}>{t("Group", { ns: "common" })}</div>
                                <div className={"form-field col-span-5"}>{t("Role", { ns: "common" })}</div>
                            </div>

                            {teacherGroup.map((group, index) => (
                                <div className="grid grid-cols-11 gap-4 items-center" key={index}>
                                    <div className={"form-field col-span-5"}>
                                        <Select
                                            options={groupsOptions}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            classNames={{
                                                control: (state) => "form-select",
                                            }}
                                            name={index}
                                            value={groupsOptions.filter((g) => g.id === group.groupId)}
                                            placeholder={t("Select...", { ns: "common" })}
                                            isSearchable={false}
                                            onChange={handleUpdateGroup}
                                        />
                                    </div>
                                    <div className={"form-field col-span-5"}>
                                        <Select
                                            options={teacherTypesOptions}
                                            getOptionLabel={(option) => t(option.name, { ns: "common" })}
                                            getOptionValue={(option) => option.id}
                                            classNames={{
                                                control: (state) => "form-select",
                                            }}
                                            name={index}
                                            value={teacherTypesOptions.filter((t) => t.id === group.typeId)}
                                            placeholder={t("Select...", { ns: "common" })}
                                            isSearchable={false}
                                            onChange={handleUpdateType}
                                        />
                                    </div>
                                    <div className={"form-field col-span-1"}>
                                        <button className="p-2" onClick={() => handleDeleteGroup(index)}>
                                            <IoTrashOutline size={"1.5em"} />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        ""
                    )}

                    <div className="flex flex-row justify-center gap-2">
                        <Button className={"btn-sm btn-primary"} onClick={() => handleAddGroup()}>
                            <IoAddOutline size={"1.5em"} />
                            {t("Add group", { ns: "button" })}
                        </Button>
                    </div>

                    <div className="border-t"></div>

                    <div className="flex flex-row justify-center gap-2">
                        <Button className={"btn-primary"} onClick={() => handleSubmit()} disabled={isSubmitting}>
                            {isSubmitting ? t("Saving", { ns: "button" }) : t("Save", { ns: "button" })}
                        </Button>
                    </div>
                </div>
            )}
        </Modal>
    );
}

export default TeacherModalGroup;
