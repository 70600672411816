import React, { useEffect, useState } from "react";

import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";
import TeacherForm from "./components/TeacherForm";

export const TeacherAddPage = () => {
    const [isLoading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState(false);

    useEffect(() => {
        AxiosInstance({
            url: "teachers",
            method: "GET",
        })
            .then((response) => {
                setPermissions(true);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, []);

    return (
        <Dashboard contentClass={"teachers"}>
            {isLoading ? <LoadingSpinner /> : permissions ? <TeacherForm /> : <AccessDenied />}
        </Dashboard>
    );
};
