import React from "react";

import { Dashboard } from "components/common";
import { Profile } from "components/account";

export const ProfilePage = () => {
    return (
        <Dashboard contentClass={"profile"}>
            <Profile />
        </Dashboard>
    );
};
