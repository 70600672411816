import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AxiosInstance from "utils/AxiosInstance";
import { LoadingSpinner } from "components/common";
import { Button, Modal } from "components/ui";

function TeacherModalDelete({ data, closeModal, handleLoadData }) {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [teacher, setTeacher] = useState(null);

    useEffect(() => {
        AxiosInstance({
            url: "/teachers/" + data.teacherId,
            method: "GET",
        }).then((response) => {
            setTeacher(response.data);

            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, []);

    const handleConfirmDeleteTeacher = async () => {
        setSubmitting(true);

        AxiosInstance({
            url: "teachers/" + teacher.id,
            method: "DELETE",
        })
            .then((response) => {
                handleLoadData();

                setTimeout(() => {
                    setSubmitting(false);
                    closeModal();
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("heading.removeTeacher", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className="flex flex-col gap-6 w-full">
                    <div className="text-center">
                        {t("Are you sure you want to remove a teacher from an institution?", { ns: "common" })}
                    </div>
                    <div className="flex flex-row justify-center gap-2">
                        <Button
                            className={"btn-primary"}
                            onClick={() => handleConfirmDeleteTeacher()}
                            disabled={isSubmitting}
                        >
                            {t("Yes", { ns: "button" })}
                        </Button>
                        <Button className={"btn-gray"} onClick={() => closeModal()}>
                            {t("No", { ns: "button" })}
                        </Button>
                    </div>
                </div>
            )}
        </Modal>
    );
}

export default TeacherModalDelete;
