import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { IoCloseOutline } from "react-icons/io5";

export const Modal = ({ open = true, handleClose, children, title = null, big = false, hideCloseButton = false }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={"paper"}
            maxWidth={big === true ? "lg" : "sm"}
            fullWidth={true}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            {title && (
                <DialogTitle id="scroll-dialog-title" className={big === true ? "!px-8" : ""}>
                    <div className="flex items-center justify-between w-full">
                        {title}
                        {hideCloseButton === false && (
                            <IconButton onClick={handleClose} className="!-mr-3">
                                <IoCloseOutline size={"1.5rem"} />
                            </IconButton>
                        )}
                    </div>
                </DialogTitle>
            )}
            <DialogContent
                sx={{
                    padding: "2rem",
                }}
            >
                {children}
            </DialogContent>
        </Dialog>
    );
};
