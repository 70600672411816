import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoCloseOutline, IoNotificationsOutline } from "react-icons/io5";

import { Avatar, Button } from "components/ui";
import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, LoadingSpinner } from "components/common";
import dayjs from "dayjs";
import { Popover } from "@mui/material";

export const Notifications = () => {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const [hoverEl, setHoverEl] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [message, setMessage] = useState(null);
    const [notifications, setNotifications] = useState(null);
    const [qty, setQty] = useState(0);

    const isOpen = Boolean(anchorEl);
    const id = isOpen ? "notifications-popover" : undefined;

    useEffect(() => {
        getNotifications();

        // const interval = setInterval(() => {
        //     getNotifications();
        // }, 8000);

        // return () => clearInterval(interval);
    }, []);

    function getNotifications() {
        AxiosInstance({
            url: "notifications/last",
            method: "GET",
        })
            .then((response) => {
                setNotifications(response.data);
                countNotifications(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }

    function countNotifications(notifications) {
        const qty = notifications.filter((notification) => notification.isSeen === false);
        setQty(qty.length);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleHoverItem = (event) => {
        setHoverEl(event.target.id);
    };

    const handleNotificationSeen = (notificationId, isSeen) => {
        AxiosInstance({
            url: "notifications/" + notificationId,
            method: "PUT",
            data: { isSeen: !isSeen },
        })
            .then((response) => {
                getNotifications();

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setMessage(error.response.data.message);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    };

    const handleNotificationHidden = (notificationId) => {
        AxiosInstance({
            url: "notifications/" + notificationId,
            method: "PUT",
            data: { isHidden: true },
        })
            .then((response) => {
                getNotifications();

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setMessage(error.response.data.message);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    };

    function stringTokenReplace(string, params) {
        Object.keys(params).forEach((key) => (string = string.replaceAll("{{" + key + "}}", params[key])));
        return string;
    }

    return (
        <>
            <div className="notifications">
                <Button className={"btn-icon"} aria-describedby={id} variant="contained" onClick={handleClick}>
                    <IoNotificationsOutline size={"1.5rem"} />
                    {qty > 0 ? <span className="qty">{qty}</span> : ""}
                </Button>
                <Popover
                    id={id}
                    open={isOpen}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    className="mt-4"
                >
                    <div className="flex flex-col w-full md:w-96 divide-y divide-gray-200">
                        <h5 className="px-4 py-3">{t("Notifications", { ns: "common" })}</h5>
                        {isLoading ? (
                            <LoadingSpinner />
                        ) : notifications ? (
                            notifications.length ? (
                                notifications.map((notification) => (
                                    <div
                                        className="flex items-center w-full gap-3 px-4 py-2 transition-all duration-300 hover:bg-gray-100"
                                        id={notification.id.toString()}
                                        key={notification.id}
                                        onMouseEnter={handleHoverItem}
                                        onMouseLeave={() => setHoverEl(null)}
                                        onClick={() => notification.action}
                                    >
                                        <div>
                                            <Avatar avatar={notification.from} />
                                        </div>
                                        <div className="flex flex-col gap-1">
                                            <strong className="text-sm">
                                                {t(notification.title, { ns: "server", notification })}
                                            </strong>
                                            <span className="text-xs text-text/80">
                                                {stringTokenReplace(
                                                    t(notification.content, { ns: "server" }),
                                                    notification.params
                                                )}
                                            </span>
                                            <span className="text-xs text-text/80">
                                                {dayjs(notification.createdAt.date).format("L")}
                                            </span>
                                        </div>
                                        {/* <div className="grow"></div> */}
                                        <div className="flex flex-col items-center gap-2 h-full w-6 relative">
                                            <span
                                                className={
                                                    "flex items-center justify-center h-6 w-6 rounded-full cursor-pointer transition-all duration-300 " +
                                                    (notification.isSeen === true ? "seen" : "")
                                                }
                                                onClick={() =>
                                                    handleNotificationSeen(notification.id, notification.isSeen)
                                                }
                                            >
                                                <span
                                                    className={
                                                        "block h-3 w-3 rounded-full " +
                                                        (notification.isSeen === true ? "bg-gray-200" : "bg-primary")
                                                    }
                                                ></span>
                                            </span>
                                            <span
                                                className={
                                                    "flex items-center justify-center p-0 rounded-full h-6 w-6 cursor-pointer transition-all duration-300 hover:bg-gray-300 " +
                                                    (notification.id.toString() === hoverEl
                                                        ? "opacity-100"
                                                        : "opacity-0")
                                                }
                                                onClick={() => handleNotificationHidden(notification.id)}
                                            >
                                                <IoCloseOutline size={"1.25rem"} />
                                            </span>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="flex items-center w-full gap-3 px-4 py-2 transition-all duration-300 hover:bg-gray-100">
                                    {t("No notifications", { ns: "common" })}
                                </div>
                            )
                        ) : (
                            <AccessDenied message={message} />
                        )}
                    </div>
                </Popover>
            </div>
        </>
    );
};
