import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { MenuItem } from "@mui/material";

import AxiosInstance from "utils/AxiosInstance";

export const DownloadPdf = ({ invoiceSlug, invoiceFileName }) => {
    const { t } = useTranslation();

    const downloadPDF = async () => {
        try {
            const { data } = await AxiosInstance.get("storage/invoice/" + invoiceSlug, {
                cache: false,
                responseType: "blob",
            });

            const blob = new Blob([data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);

            // Create a temporary <a> element to trigger the download
            const tempLink = document.createElement("a");
            tempLink.href = url;
            tempLink.setAttribute("download", invoiceFileName); // Set the desired filename for the downloaded file

            // Append the <a> element to the body and click it to trigger the download
            document.body.appendChild(tempLink);
            tempLink.click();

            // Clean up the temporary elements and URL
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            const errorObj = JSON.parse(await error.response.data.text());

            enqueueSnackbar(t(errorObj.message, { ns: "server" }), {
                variant: "error",
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
        }
    };

    return (
        <MenuItem
            onClick={downloadPDF}
            className="!text-sm"
            sx={{
                gap: "0.5rem",
            }}
        >
            <IoCloudDownloadOutline size={"1.25em"} />
            {t("Download invoice", { ns: "button" })}
        </MenuItem>
    );
};
