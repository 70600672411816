import React from "react";
import { Alert } from "./Alert";

export const Alerts = ({ alerts, classNames = "", defaultType = "error" }) => {
    const getType = (alert) => {
        if (alert.status) {
            switch (alert.status) {
                case 200:
                case 201:
                    return "success";
                case 400:
                case 403:
                case 404:
                    return "warning";
                case 500:
                    return "error";
            }
        } else {
            return defaultType;
        }
    };

    return (
        <div className={"mb-4 " + classNames}>
            {alerts.constructor === Array ? (
                alerts.map((alert) => <Alert message={alert.message} type={getType(alert)} />)
            ) : (
                <Alert
                    message={alerts.message ? alerts.message : alerts.detail ? alerts.detail : alerts}
                    type={getType(alerts)}
                />
            )}
        </div>
    );
};
