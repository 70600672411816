import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";

import CurrentUser from "services/CurrentUser";
import { Notifications } from "./Notifications";
import { Avatar, Button, LanguageSwitcher } from "components/ui";
import { Drawer } from "@mui/material";
import { Menu } from "./Menu";
import { IoMenuOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";

export const Navbar = () => {
    const { t } = useTranslation();
    const currentUser = CurrentUser();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const theme = useTheme();
    const showMenu = useMediaQuery(theme.breakpoints.up("xl"));

    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        setMobileMenuOpen(open);
    };

    return (
        <>
            {currentUser && (
                <div className="navbar">
                    <div className="navbar-content">
                        {!showMenu ? (
                            <React.Fragment key={"left"}>
                                <Button onClick={toggleDrawer(true)} className="!px-2 -ml-3">
                                    <IoMenuOutline size={"1.75rem"} />
                                </Button>
                                <Drawer open={mobileMenuOpen} onClose={toggleDrawer(false)}>
                                    <Menu />
                                </Drawer>
                            </React.Fragment>
                        ) : null}

                        {/* <div className="navbar-left">
                            <div className="inline-flex ml-24 text-xs text-gray-500 gap-3">
                                <span>{currentUser.id}</span>
                                <span>{currentUser.fullName}</span>
                                <span>{currentUser.roles}</span>
                            </div>
                        </div> */}

                        <div className="grow"></div>

                        <LanguageSwitcher onlyFlag={true} />

                        <Notifications />

                        <NavLink to="/account/profile">
                            <Avatar avatar={currentUser.avatar} />
                        </NavLink>
                    </div>
                </div>
            )}
        </>
    );
};
