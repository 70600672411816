import React from "react";
import { useTranslation } from "react-i18next";

import { Button, ImgWithFallback } from "components/ui";
import { Layout } from "./Layout";

import signUpWebP from "../img/signup-step-1.webp";
import signUpPNG from "../img/signup-step-1.png";

export const Step4 = ({ handleChange, nextStep, resetSignUp, reSendCodeOTP, loginBy, isSubmitting, data, error }) => {
    const { t } = useTranslation();

    const signUpImg = (
        <ImgWithFallback src={signUpWebP} fallback={signUpPNG} className="flex max-w-xl m-auto" alt="Rejestracja" />
    );

    return (
        <Layout signUpImg={signUpImg}>
            <h3>{t("Welcome to The Kidly!", { ns: "common" })}</h3>
            <p className="mb-0">
                {t("Complete the following fields to complete your registration.", { ns: "common" })}
            </p>

            <div className="flex flex-col w-full gap-2">
                {loginBy === "email" ? (
                    <div className="form-field">
                        <label htmlFor="phone" className="form-label">
                            {t("Phone number", { ns: "common" })}
                        </label>
                        <input
                            type="tel"
                            name="phone"
                            id="phone"
                            className="form-input"
                            pattern="[0-9]{9}"
                            maxLength={9}
                            onChange={handleChange("phone")}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") nextStep();
                            }}
                            value={data.phone}
                        />
                    </div>
                ) : (
                    <div className="form-field">
                        <label htmlFor="lastName" className="form-label">
                            {t("Email", { ns: "common" })}
                        </label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="form-input"
                            maxLength={180}
                            onChange={handleChange("email")}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") nextStep();
                            }}
                            value={data.email}
                        />
                    </div>
                )}
                <div className="form-field">
                    <label htmlFor="firstName" className="form-label">
                        {t("First name", { ns: "common" })}
                    </label>
                    <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        className="form-input"
                        maxLength={255}
                        onChange={handleChange("firstName")}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") nextStep();
                        }}
                        value={data.firstName}
                    />
                </div>
                <div className="form-field">
                    <label htmlFor="lastName" className="form-label">
                        {t("Last name", { ns: "common" })}
                    </label>
                    <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        className="form-input"
                        maxLength={255}
                        onChange={handleChange("lastName")}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") nextStep();
                        }}
                        value={data.lastName}
                    />
                </div>
            </div>

            <Button className={"btn-black w-full"} onClick={nextStep} disabled={isSubmitting}>
                {isSubmitting ? t("Sending", { ns: "button" }) : t("Next", { ns: "button" })}
            </Button>
        </Layout>
    );
};
