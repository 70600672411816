import React from "react";
import parse from "html-react-parser";

import {
    IoAlertCircleOutline,
    IoCheckmarkCircleOutline,
    IoCloseCircleOutline,
    IoInformationCircleOutline,
} from "react-icons/io5";

function nl2br(str, replaceMode, isXhtml) {
    var breakTag = isXhtml ? "<br />" : "<br>";
    var replaceStr = replaceMode ? "$1" + breakTag : "$1" + breakTag + "$2";
    return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
}

export const Alert = ({ message, type = "success", classNames = "" }) => {
    let icon = "";

    switch (type) {
        case "error":
            icon = <IoCloseCircleOutline size={"1.5rem"} />;
            break;
        case "warning":
            icon = <IoAlertCircleOutline size={"1.5rem"} />;
            break;
        case "success":
            icon = <IoCheckmarkCircleOutline size={"1.5rem"} />;
            break;
        case "info":
            icon = <IoInformationCircleOutline size={"1.5rem"} />;
            break;
    }

    return (
        <div className={"alert alert-" + type + " col-span-full " + classNames}>
            {icon} <span style={{ width: `calc(100% - 2rem)` }}>{parse(nl2br(message))}</span>
        </div>
    );
};
