import React from "react";

export const BoxItem = ({ className = "", icon, label, children }) => {
    return (
        <div className={"box-item " + className}>
            {icon}
            <div>
                <strong className="mr-1">{label}:</strong> {children}
            </div>
        </div>
    );
};
