import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IoAddOutline, IoCreateOutline, IoEyeOutline, IoHappyOutline } from "react-icons/io5";

import { Badge, ContentHeading, OptionMenu } from "components/ui";
import KidsModalView from "./KidsModalView";
import { GroupIcon } from "./GroupIcon";
import { DataGrid } from "components/grid";

function KidsList({ kids, handleLoadData }) {
    const { t } = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();
    const [modal, setModal] = useState({ show: false, data: null });
    const [filterModel, setFilterModel] = useState({ items: [] });

    const columns = [
        { field: "fullName", headerName: t("Full name", { ns: "common" }), width: 240 },
        {
            field: "group",
            headerName: t("Group", { ns: "common" }),
            width: 180,
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-4">
                        <GroupIcon icon={params.row.group.icon} color={params.row.group.color} size={"1.25em"} />
                        {params.row.group.name || ""}
                    </div>
                );
            },
        },
        {
            field: "joinStatus",
            headerName: t("Status", { ns: "common" }),
            width: 180,
            renderCell: (params) => {
                let color = "";
                switch (params.row.joinStatus) {
                    case "awaiting_approval":
                        color = "#dc2626";
                        break;
                    case "accepted":
                        color = "#166534";
                        break;
                    case "rejected":
                        color = "";
                        break;
                    case "removed":
                        color = "";
                        break;
                    default:
                        break;
                }
                return (
                    <div className="flex items-center h-full">
                        <Badge color={color}>{t(params.row.joinStatus, { ns: "common" })}</Badge>
                    </div>
                );
            },
        },
        {
            field: "birthday",
            headerName: t("Birthday", { ns: "common" }),
            width: 180,
        },
        {
            field: "diet",
            headerName: t("Diet", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) =>
                `${row.joinStatus === "accepted" && row.diet ? t(row.diet.name, { ns: "common" }) : "---"}`,
        },
        {
            field: "allergies",
            headerName: t("Allergies", { ns: "common" }),
            minWidth: 180,
            flex: 1,
            valueGetter: (value, row) =>
                `${
                    (row.joinStatus === "accepted" &&
                        row.allergies &&
                        row.allergies.map((allergy) => t(allergy.name, { ns: "common" })).join(", ")) ||
                    "---"
                }`,
        },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            width: 120,
            renderCell: (params) => {
                let iconButtons = [
                    {
                        icon: <IoEyeOutline size={"1.25rem"} />,
                        tooltipProps: {
                            title: t("View details", { ns: "button" }),
                        },
                        menuItemProps: {
                            onClick: () => openModal(params.id),
                        },
                    },
                ];

                let options = [];
                params.row.joinStatus === "accepted" &&
                    options.push(
                        {
                            text: t("Edit", { ns: "button" }),
                            icon: <IoCreateOutline size={"1.25em"} />,
                            href: "/institution/kids/" + params.id + "/edit",
                        }
                        // {
                        //     text: t("Remove", { ns: "button" }),
                        //     icon: <IoTrashOutline size={"1.25em"} />,
                        //     menuItemProps: {
                        //         onClick: () => openModal(params.id, "delete"),
                        //     },
                        // }
                    );

                return <OptionMenu iconButtons={iconButtons} options={options} />;
            },
        },
    ];

    useEffect(() => {
        setFilterModel({
            items: [
                {
                    field: "group",
                    operator: "contains",
                    value: searchParams.get("group"),
                },
            ],
        });
    }, [searchParams]);

    const openModal = (kidId) => {
        setModal({ show: true, data: { kidId: kidId } });
    };

    const handleModalClose = () => {
        handleLoadData();
        setModal({ show: false, data: null });
    };

    return (
        <>
            <ContentHeading
                tag="h3"
                showBtn={true}
                btnUrl={"/institution/kids/new"}
                btnIcon={<IoAddOutline size={"1.125rem"} />}
                btnLabel={t("Add kid", { ns: "button" })}
            >
                <IoHappyOutline size={"1.5rem"} />
                {t("List of kids", { ns: "common" })}
            </ContentHeading>

            <DataGrid rows={kids} columns={columns} />

            {modal.show && modal.data && <KidsModalView data={modal.data} closeModal={handleModalClose} />}
        </>
    );
}

export default KidsList;
