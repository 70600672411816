import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AxiosInstance from "utils/AxiosInstance";

export const LoadRoles = (type = "all") => {
    const { t } = useTranslation("roles");
    const [roles, setRoles] = useState(true);

    useEffect(() => {
        let url = "roles";

        if (type === "provider") {
            url = url + "/provider";
        } else if (type === "institution") {
            url = url + "/institution";
        }

        AxiosInstance({
            url: url,
            method: "GET",
        }).then((response) => {
            let temp = [];

            response.data.map((role) => temp.push({ key: role, name: t("long." + role) }));

            setRoles(temp);
        });
    }, [t, type]);

    return roles;
};
