import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Menu } from "@headlessui/react";
import { IoAddOutline, IoCreateOutline, IoEllipsisVerticalOutline, IoPeopleOutline } from "react-icons/io5";

import AxiosInstance from "utils/AxiosInstance";
import { LoadingSpinner } from "components/common";
import { Avatar, Box, CallTo, ContentHeading, MailTo, RoleBadge } from "components/ui";
import TrainerModalActivity from "./TrainerModalActivity";
import TrainerModalDelete from "./TrainerModalDelete";
import TrainerModalRole from "./TrainerModalRole";
import TrainerModalWorkSchedule from "./TrainerModalWorkSchedule";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function TrainerList({ brandId }) {
    const { t } = useTranslation();

    const [modal, setModal] = useState({ show: false, data: null, type: null });
    const [isLoading, setLoading] = useState(true);
    const [trainers, setTrainers] = useState([]);

    useEffect(() => {
        handleLoadData();
    }, []);

    const handleLoadData = () => {
        AxiosInstance({
            url: "brands/" + brandId + "/trainers",
            method: "GET",
        })
            .then((response) => {
                setTrainers(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    };

    const openModal = (trainerId, type = "view") => {
        setModal({ show: true, data: { brandId: brandId, trainerId: trainerId }, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
    };

    return (
        <>
            <div className="flex flex-col gap-6 trainers">
                <ContentHeading
                    showBtn={true}
                    btnUrl={"/brand/brands/" + brandId + "/trainers/new"}
                    btnIcon={<IoAddOutline size={"1.125em"} />}
                    btnLabel={t("Add trainer", { ns: "button" })}
                >
                    <IoPeopleOutline size={"1.25em"} />
                    {t("Trainers", { ns: "common" })}
                </ContentHeading>

                {isLoading ? (
                    <LoadingSpinner />
                ) : trainers.length ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-6">
                        {trainers.map((trainer, index) => (
                            <Box className={"box-items relative"} key={index}>
                                <div className="flex gap-2 absolute top-4 right-4">
                                    <Link to={"/brand/brands/" + brandId + "/trainers/" + trainer.id + "/edit"}>
                                        <IoCreateOutline size={"1.125em"} />
                                    </Link>

                                    <Menu as={"div"} className={"relative"}>
                                        <Menu.Button>
                                            <IoEllipsisVerticalOutline size={"1.125em"} />
                                        </Menu.Button>
                                        <Menu.Items
                                            className={
                                                "absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            }
                                        >
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        type="button"
                                                        className={classNames(
                                                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                                            "block w-full px-4 py-2 text-sm text-left"
                                                        )}
                                                        onClick={() => openModal(trainer.id, "activities")}
                                                    >
                                                        {t("Activities", { ns: "button" })}
                                                    </button>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        type="button"
                                                        className={classNames(
                                                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                                            "block w-full px-4 py-2 text-sm text-left"
                                                        )}
                                                        onClick={() => openModal(trainer.id, "role")}
                                                    >
                                                        {t("User roles", { ns: "button" })}
                                                    </button>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        type="button"
                                                        className={classNames(
                                                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                                            "block w-full px-4 py-2 text-sm text-left"
                                                        )}
                                                        onClick={() => openModal(trainer.id, "workSchedule")}
                                                    >
                                                        {t("Work schedule", { ns: "button" })}
                                                    </button>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        type="button"
                                                        className={classNames(
                                                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                                            "block w-full px-4 py-2 text-sm text-left"
                                                        )}
                                                        onClick={() => openModal(trainer.id, "removeTrainer")}
                                                    >
                                                        {t("Remove trainer", { ns: "button" })}
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Menu>
                                </div>
                                <div className="flex justify-center">
                                    <Avatar avatar={trainer.avatar} size={80} />
                                </div>
                                <div className="text-center">
                                    <h3>{trainer.fullName}</h3>
                                    <span className="text-sm">
                                        {trainer.activities.map((activity) => activity.name).join(", ")}
                                    </span>
                                </div>
                                <div className="flex justify-center gap-2">
                                    {trainer.roles.map((role, index) => (
                                        <RoleBadge role={role} key={index} />
                                    ))}
                                </div>
                                <div className="flex gap-2">
                                    <strong>{t("E-mail", { ns: "common" })}:</strong>
                                    <MailTo email={trainer.email}>{trainer.email}</MailTo>
                                </div>
                                <div className="flex gap-2">
                                    <strong>{t("Phone", { ns: "common" })}:</strong>
                                    <CallTo phone={trainer.phone}>{trainer.phone}</CallTo>
                                </div>
                            </Box>
                        ))}
                    </div>
                ) : (
                    t("No trainers found", { ns: "common" })
                )}
            </div>

            {modal.show && modal.data && modal.type === "activities" && (
                <TrainerModalActivity data={modal.data} closeModal={handleModalClose} handleLoadData={handleLoadData} />
            )}

            {modal.show && modal.data && modal.type === "role" && (
                <TrainerModalRole data={modal.data} closeModal={handleModalClose} handleLoadData={handleLoadData} />
            )}

            {modal.show && modal.data && modal.type === "workSchedule" && (
                <TrainerModalWorkSchedule data={modal.data} closeModal={handleModalClose} />
            )}

            {modal.show && modal.data && modal.type === "removeTrainer" && (
                <TrainerModalDelete data={modal.data} closeModal={handleModalClose} handleLoadData={handleLoadData} />
            )}
        </>
    );
}

export default TrainerList;
