import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    IoAddOutline,
    IoAttachOutline,
    IoBasketballOutline,
    IoCallOutline,
    IoCashOutline,
    IoCreateOutline,
    IoInformationCircleOutline,
    IoLocationOutline,
    IoMailOutline,
    IoRibbonOutline,
    IoShieldOutline,
} from "react-icons/io5";

import AxiosInstance from "utils/AxiosInstance";
import { DataEmpty, LoadingSpinner } from "components/common";
import { Box, BoxItem, Button, ContentHeading } from "components/ui";
import ActivityList from "./ActivityList";
import TrainerList from "./TrainerList";

function BrandList({ brands }) {
    const { t } = useTranslation();
    const [activeBrand, setActiveBrand] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [brand, setBrand] = useState(null);

    useEffect(() => {
        if (brands.length) {
            setLoading(true);

            AxiosInstance({
                url: "brands/" + brands[activeBrand].id,
                method: "GET",
            })
                .then((response) => {
                    setBrand(response.data);

                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                })
                .catch((error) => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                });
        } else {
            setLoading(false);
        }
    }, [activeBrand]);

    return (
        <>
            <ContentHeading
                tag="h3"
                showBtn={true}
                btnUrl={"/brand/brands/new"}
                btnIcon={<IoAddOutline size={"1.125rem"} />}
                btnLabel={t("Add brand", { ns: "button" })}
            >
                <IoRibbonOutline size={"1.5rem"} />
                {t("My offer", { ns: "common" })}
            </ContentHeading>

            {brands.length > 1 && (
                <div className="content-links">
                    {brands.map((brand, index) => (
                        <Button
                            className={index === activeBrand ? "btn-primary" : "btn-dark-outline btn-opacity"}
                            onClick={() => setActiveBrand(index)}
                            key={index}
                        >
                            {brand.name}
                        </Button>
                    ))}
                </div>
            )}

            {isLoading ? (
                <LoadingSpinner />
            ) : brand ? (
                <div className={"content-inner brand"}>
                    <Box className={"!p-0"}>
                        <div className="content-inner-bg"></div>
                        <div className="content-inner-name">
                            <div className="logo"></div>
                            <h4>{brand.name}</h4>
                        </div>
                    </Box>

                    <ContentHeading
                        showBtn={true}
                        btnUrl={"/brand/brands/" + brand.id + "/edit"}
                        btnIcon={<IoCreateOutline size={"1.125em"} />}
                        btnLabel={t("Edit", { ns: "button" })}
                    >
                        <IoInformationCircleOutline size={"1.25em"} />
                        {t("Information", { ns: "common" })}
                    </ContentHeading>

                    <div className="flex flex-col md:flex-row gap-6">
                        <Box className={"box-items w-full md:w-7/12 xl:w-4/6"} title={t("Company information", { ns: "common" })}>
                            <BoxItem
                                icon={<IoShieldOutline size={"1.25em"} />}
                                label={t("Company name", { ns: "common" })}
                            >
                                {brand.name}
                            </BoxItem>
                            <BoxItem icon={<IoCallOutline size={"1.25em"} />} label={t("Phone", { ns: "common" })}>
                                {brand.phone}
                            </BoxItem>
                            <BoxItem icon={<IoMailOutline size={"1.25em"} />} label={t("E-mail", { ns: "common" })}>
                                {brand.email}
                            </BoxItem>
                            <BoxItem
                                icon={<IoLocationOutline size={"1.25em"} />}
                                label={t("Address", { ns: "common" })}
                            >
                                {brand.street} {brand.number} {brand.premises}, {brand.zipCode} {brand.city}
                            </BoxItem>
                            <BoxItem
                                icon={<IoCashOutline size={"1.25em"} />}
                                label={t("Bank Account No.", { ns: "common" })}
                            >
                                {brand.accountNumber}
                            </BoxItem>
                            <BoxItem
                                icon={<IoBasketballOutline size={"1.25em"} />}
                                label={t("Activity description", { ns: "common" })}
                            >
                                {brand.activityDescription}
                            </BoxItem>
                            <BoxItem
                                icon={<IoAttachOutline size={"1.25em"} />}
                                label={t("Attachments", { ns: "common" })}
                            >
                                ---
                            </BoxItem>
                        </Box>
                        <Box className={"w-full md:w-5/12 xl:w-2/6"} title={t("Company description", { ns: "common" })}>
                            <p>{brand.companyDescription}</p>
                        </Box>
                    </div>

                    <ActivityList brandId={brand.id} />
                    <TrainerList brandId={brand.id} />
                </div>
            ) : (
                <DataEmpty title={t("No offers found", { ns: "common" })} />
            )}
        </>
    );
}

export default BrandList;
