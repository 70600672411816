import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { LoadingSpinner } from "components/common";
import { Alert, Alerts, Button, Modal } from "components/ui";
import AxiosInstance from "utils/AxiosInstance";
import { enqueueSnackbar } from "notistack";

const optionsForms = {
    cardIcon: true,
    // fonts: [
    //     {
    //         family: "Plus Jakarta Sans",
    //         src: 'url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko40yyygA.woff2) format("woff2")',
    //         display: "swap",
    //         style: "normal",
    //         weight: 400,
    //         unicodeRange:
    //             "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD",
    //     },
    // ],
    style: {
        basic: {
            fontSize: "18px",
            // fontFamily: "Plus Jakarta Sans",
            // fontWeight: "400",
        },
    },
    placeholder: {
        number: "Numer karty",
        date: "MM/YY",
        cvv: "Kod CVV",
    },
    lang: "pl",
};

export const SecureForm = ({ handleSaveCard }) => {
    const { t } = useTranslation();

    const [message, setMessage] = useState(null);

    useEffect(() => {
        const scriptTag = document.createElement("script");
        scriptTag.src = process.env.REACT_APP_PAYU_SCRIPT;
        document.body.appendChild(scriptTag);

        scriptTag.onload = () => {
            const payuSdkForms = new window.PayU(process.env.REACT_APP_PAYU_POS_ID);
            const secureForms = payuSdkForms.secureForms();

            const cardNumber = secureForms.add("number", optionsForms);
            const cardDate = secureForms.add("date", optionsForms);
            const cardCvv = secureForms.add("cvv", optionsForms);

            cardNumber.render("#payu-card-number");
            cardDate.render("#payu-card-date");
            cardCvv.render("#payu-card-cvv");

            const tokenizeButton = document.getElementById("tokenizeButton");
            const responseElement = document.getElementById("responseTokenize");

            tokenizeButton.addEventListener("click", function () {
                responseElement.innerText = "";

                try {
                    payuSdkForms.tokenize("MULTI").then(function (result) {
                        if (result.status === "SUCCESS") {
                            setMessage(null);
                            handleSaveCard(result.body);
                        } else {
                            setMessage(result.error.messages);
                        }
                    });
                } catch (e) {
                    enqueueSnackbar(e, {
                        variant: "error",
                        anchorOrigin: { vertical: "bottom", horizontal: "right" },
                    });
                }
            });
        };
    }, []);

    return (
        <div className={"payu"}>
            {message && <Alerts alerts={message} classNames={"grid gap-2"} />}

            <div className="grid grid-cols-12 items-center gap-3 card-container">
                <div className="col-span-full">
                    <div className="form-input" id="payu-card-number"></div>
                </div>

                <div className="col-span-6 expiration">
                    <div className="form-input" id="payu-card-date"></div>
                </div>

                <div className="col-span-6 cvv">
                    <div className="form-input" id="payu-card-cvv"></div>
                </div>
            </div>

            <div className="flex flex-row justify-center mt-3">
                <Button className={"btn-primary"} id="tokenizeButton">
                    {t("Save card", { ns: "button" })}
                </Button>
            </div>

            <div id="responseTokenize"></div>
        </div>
    );
};
