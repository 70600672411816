import React from "react";
import { useTranslation } from "react-i18next";

export const AccessDenied = ({ message }) => {
    const { t } = useTranslation();

    return (
        <div className="grid h-full w-full p-8 justify-center items-center">
            <div>
                <h2 className="m-auto">{t("403 | No authority", { ns: "common" })}</h2>
                {message && <p className="m-auto mt-4 text-center">{message}</p>}
            </div>
        </div>
    );
};
